import { useQuery, UseQueryResult } from 'react-query';
import { useAggregatedQueries } from 'src/hooks/useAggregatedQuery';
import { DeviceContractLedgerEntry } from 'src/types/DeviceContractLedgerEntry';
import { get, post, destroy } from '../utils';

export function createDeviceContractLedgerEntry(contract: Partial<DeviceContractLedgerEntry>): Promise<DeviceContractLedgerEntry> {
  return post<DeviceContractLedgerEntry>('devicecontractledger', contract);
}

export function getDeviceContractLedgerEntry(deviceContractLedgerEntryId: string): Promise<DeviceContractLedgerEntry> {
  return get<DeviceContractLedgerEntry>(`devicecontractledger/${deviceContractLedgerEntryId}`);
}

export function deleteDeviceContractLedgerEntry(deviceContractLedgerEntryId: string): Promise<DeviceContractLedgerEntry> {
  return destroy(`devicecontractledger/${deviceContractLedgerEntryId}`);
}

export function findDeviceContractLedgerEntriesByContract(
  deviceContractId: string,
  includeDeleted?: boolean
): Promise<DeviceContractLedgerEntry[]> {
  return get<DeviceContractLedgerEntry[]>('devicecontractledger', {
    deviceContractId,
    includeDeleted,
  });
}

export function useQueryDeviceContractLedgerEntriesByContract(
  deviceContractId: string,
  includeDeleted?: boolean
): UseQueryResult<DeviceContractLedgerEntry[]> {
  return useQuery(['DeviceContractLedgerEntries', deviceContractId, includeDeleted], () =>
    findDeviceContractLedgerEntriesByContract(deviceContractId, includeDeleted)
  );
}

export function useQueryDeviceContractLedgerEntriesForContracts(
  deviceContractIds: string[],
  includeDeleted?: boolean,
  enabled?: boolean
) {
  return useAggregatedQueries<DeviceContractLedgerEntry[]>(
    enabled !== undefined && !enabled
      ? []
      : deviceContractIds.map((id) => {
          const current = id;
          return {
            queryKey: ['DeviceContractLedgerEntries', id, includeDeleted],
            queryFn: async () => findDeviceContractLedgerEntriesByContract(current, includeDeleted),
          };
        })
  );
}

export function findDeviceContractLedgerEntriesByDevice(deviceId: string, includeDeleted?: boolean): Promise<DeviceContractLedgerEntry[]> {
  return get<DeviceContractLedgerEntry[]>('devicecontractledger', {
    deviceId,
    includeDeleted,
  });
}

export function useQueryDeviceContractLedgerEntriesByDevice(
  deviceId: string,
  includeDeleted?: boolean
): UseQueryResult<DeviceContractLedgerEntry[]> {
  return useQuery(['DeviceContractLedgerEntries', deviceId, includeDeleted], () => findDeviceContractLedgerEntriesByDevice(deviceId));
}
