import { GenericDevice } from './GenericDevice';

export enum DeviceType {
  ENVIROQ = 'ENVIROQ',
  THERMALQ = 'THERMALQ',
  AIRWITS_PM = 'AIRWITS_PM',
  AIRWITS_CO2 = 'AIRWITS_CO2',
  AIRWITS = 'AIRWITS',
  DETECTIFY = 'DETECTIFY',
  ADEUNIS_PULSE_3 = 'ADEUNIS_PULSE_3',
  ADEUNIS_PULSE_WATER = 'ADEUNIS_PULSE_WATER',
  FLEXSENSE = 'FLEXSENSE',
  VOLTDROP = 'VOLTDROP',
}

export class Device implements GenericDevice {
  id: string;

  type: string;

  serialNumber: string;

  sku: string;

  shortSerial: string;

  loraNetworkDetails: {
    devEUI: string;
    appKey: string;
    appEUI: string;
    frequencyPlan: 'AS923';
    network: 'SPARK_NZ' | 'TETHER_GATEWAY';
  };

  sigfoxNetworkDetails: {
    sigfoxId: string;
    sigfoxPAC: string;
    radioConfiguration: 'RC4';
  };

  mqttNetworkDetails?: {
    clientId: string;
    provisioningParameters?: {
      certificatePem?: string;
      privateKey?: string;
      endpointAddress?: string;
      topic?: string;
    }
  };

  hardwareVersion: string;

  firmwareVersion: string;

  quickCircuitId: string;

  parentDeviceId?: string;

  subDeviceIndex?: number;

  subDevices?: Device[];

  network?: string;

  createdAt: string;

  updatedAt: string;

  deletedAt: string;

  constructor(props: Device) {
    Object.keys(props).forEach((key) => (this[key] = props[key]));
  }

  getDeviceType(): string {
    return this.type;
  }

  getFriendlyDeviceType(): string {
    switch (this.getDeviceType()) {
      case DeviceType.ENVIROQ:
        return 'EnviroQ';
      case DeviceType.THERMALQ:
        return 'ThermalQ';
      default:
        return this.getDeviceType();
    }
  }

  getPhysicalLabel(): string {
    return this.shortSerial;
  }
}
