import { StockaInventoryItem } from 'src/components/inventory/StockaInventoryItemPanel';
import { StockaOrderSync } from 'src/types/StockaOrderSync';
import { UseQueryResult, useQuery } from 'react-query';
import { get, post } from '../utils';

export function getStockaInventory(): Promise<StockaInventoryItem[]> {
  return get<StockaInventoryItem[]>('stocka/inventory');
}

export function getStockaOrderState(orderId: string): Promise<StockaOrderSync> {
  return get<StockaOrderSync>(`stocka/order/${orderId}`);
}

export async function forceSyncStocka(customerOrderId: string) {
  try {
    await post(`customerorder/${customerOrderId}/stockasync`, {});
  } catch (error) {
    if (error.statusCode === 404) {
      return;
    }

    throw error;
  }
}

export function useGetStockaSync(customerOrderId: string | undefined) {
  const { data: stockaSync, ...rest }: UseQueryResult<StockaOrderSync | undefined> = useQuery(
    ['StockaSync', customerOrderId],
    async () => {
      return get<StockaOrderSync>(`customerorder/${customerOrderId}/stockasync`);
    },
    { enabled: !!customerOrderId }
  );

  return {
    stockaSync,
    ...rest,
  };
}
