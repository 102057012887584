import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import useLog from 'src/hooks/useLog';
import { DeviceInventoryLog } from 'src/types/DeviceInventoryLog';
import { DeviceInventoryLevel } from 'src/types/DeviceInventoryStatus';
import { destroy, get, post } from '../utils';

enum QueryKeys {
  INVENTORY_LEVEL = 'inventory-level',
  INVENTORY_LOG = 'inventory-log',
}

export function getStockLevelsForDeviceType(deviceType: string): Promise<DeviceInventoryLog[]> {
  const queryParams = {
    logType: 'STOCK_LEVEL',
    deviceType,
  };
  return get<DeviceInventoryLog[]>('deviceinventorylog', queryParams);
}

export function useGetInventoryLevelForDeviceType(deviceType: string, options?: UseQueryOptions<DeviceInventoryLevel, Error>) {
  const queryParams = {
    deviceType,
  };
  return useQuery<DeviceInventoryLevel, Error>(
    [QueryKeys.INVENTORY_LEVEL, deviceType],
    () => get<DeviceInventoryLevel>('deviceinventorylog/inventorylevel', queryParams),
    options
  );
}

export function useCreateDeviceInventoryStockLevelMutation() {
  const queryClient = useQueryClient();
  const log = useLog();

  const create = async ({ deviceType, value, note }: { deviceType: string; value: string; note?: string }) => {
    const body = {
      logType: 'STOCK_LEVEL',
      deviceType,
      value,
      note,
    };
    return post<DeviceInventoryLog>('deviceinventorylog', body);
  };

  return useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.INVENTORY_LEVEL);
    },
    onError: (err: Error) => {
      log.error(`Unable to delete log entry: ${err.message}`, err);
    },
  });
}

export function useGetDeviceInventoryLogForDeviceType(deviceType: string, options?: UseQueryOptions<DeviceInventoryLog[], Error>) {
  const queryParams = {
    deviceType,
    includeDeleted: true,
  };
  return useQuery<DeviceInventoryLog[], Error>(
    [QueryKeys.INVENTORY_LOG, deviceType],
    () => get('deviceinventorylog', queryParams),
    options
  );
}

export function useDeleteDeviceInventoryLogMutation() {
  const queryClient = useQueryClient();
  const log = useLog();

  return useMutation(
    async (id: string) => {
      await destroy(`deviceinventorylog/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.INVENTORY_LOG);
        queryClient.invalidateQueries(QueryKeys.INVENTORY_LEVEL);
      },
      onError: (err: Error) => {
        log.error(`Unable to delete log entry: ${err.message}`, err);
      },
    }
  );
}
