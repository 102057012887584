import { createContext, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

type LogItemType = 'success' | 'error' | 'warning' | 'info' | 'debug';
interface LogItem {
  message: string;
  details: string[];
  type: LogItemType
}

interface State {
  logItems: LogItem[],
}

export interface LogContextValue extends State {
  log: (message: string, type: LogItemType, ...details: any[]) => void;
  success: (message: string, ...details: any[]) => void;
  error: (message: string, ...details: any[]) => void;
  warning: (message: string, ...details: any[]) => void;
  info: (message: string, ...details: any[]) => void;
  debug: (message: string, ...details: any[]) => void;
}

interface LogProviderProps {
  children: ReactNode;
}

type CreateLogItemAction = {
  type: 'CREATE_LOG_ITEM';
  payload: {
    logItem: LogItem
  };
};

type Action = CreateLogItemAction;

const initialState: State = {
  logItems: [],
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  CREATE_LOG_ITEM: (state: State, action: CreateLogItemAction): State => {
    const { logItem } = action.payload;
    const logItems = [...state.logItems];
    logItems.push(logItem);

    return {
      ...state,
      logItems
    };
  },
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const LogContext = createContext<LogContextValue>({
  ...initialState,
  log: () => {},
  success: () => {},
  error: () => {},
  info: () => {},
  warning: () => {},
  debug: () => {},
});

export const LogProvider: FC<LogProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  const log = (message: string, type: LogItemType, ...details: any[]): void => {
    const detailStrings = details.map((detail) => (typeof (detail) === 'object' ? JSON.stringify(detail) : detail));

    if (type === 'success' || type === 'error' || type === 'warning' || type === 'info') {
      enqueueSnackbar(message, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: type
      });
    }

    dispatch({
      type: 'CREATE_LOG_ITEM',
      payload: {
        logItem: {
          message,
          details: detailStrings,
          type,
        }
      }
    });
  };

  const success = (message: string, ...details: any[]): void => {
    log(message, 'success', ...details);
  };

  const error = (message: string, ...details: any[]): void => {
    log(message, 'error', ...details);
  };

  const warning = (message: string, ...details: any[]): void => {
    log(message, 'warning', ...details);
  };

  const info = (message: string, ...details: any[]): void => {
    log(message, 'info', ...details);
  };

  const debug = (message: string, ...details: any[]): void => {
    log(message, 'debug', ...details);
  };

  return (
    <LogContext.Provider
      value={{
        ...state,
        log,
        success,
        error,
        warning,
        info,
        debug,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};

LogProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LogContext;
