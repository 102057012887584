import { DatePicker, DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { enNZ as locale } from "date-fns/locale";
import { useRef, useState } from "react";

interface TetherDatePickerProps<T> extends DatePickerProps<T> {
  size?: 'small' | 'medium';
}
const TetherDatePicker = <T,>({ sx, slotProps, format, size, ...props }: TetherDatePickerProps<T>) => {
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} >
        <DatePicker
          inputRef={ref}
          sx={{
            input: {
              cursor: 'pointer',
            },
            ...sx
          }}
          open={datePickerOpen}
          onClose={() => {
            setDatePickerOpen(false)
            // Stop it highlighting the text field
            ref.current?.focus();
          }}
          slotProps={{
            textField: {
              inputProps: { readOnly: true },
              onClick: () => {
                if (!datePickerOpen) {
                  setDatePickerOpen(true);
                }
              },
              size: size
            },
            ...slotProps
          }}
          // Hide the format if the value is empty or it'll display it in the field because... stupid?
          format={props.value ? format : undefined}
          {...props}
        />

      </LocalizationProvider>
    </>
  );
}

export default TetherDatePicker;