import { FC, useState } from 'react';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import useLog from './hooks/useLog';
import useSettings from './hooks/useSettings';
import ChevronDown from './icons/ChevronDown';
import ChevronUp from './icons/ChevronUp';

interface LogProps {
  showDebug: boolean;
}

const Log: FC<LogProps> = (props) => {
  const { showDebug } = props;
  const { logItems } = useLog();
  const [expandedItems, setExpandedItems] = useState([]);
  const { settings, saveSettings } = useSettings();
  const theme = useTheme();

  const handleMouseDrag = (e) => {
    let mainWindowWidth = Math.round((e.clientX * 100) / window.innerWidth);
    if (mainWindowWidth > 100) {
      mainWindowWidth = 95;
    }
    saveSettings({ ...settings, logWidth: 100 - mainWindowWidth });
  };

  const handleMouseRelease = () => {
    window.removeEventListener('mouseup', handleMouseRelease);
    window.removeEventListener('mousemove', handleMouseDrag);
  };

  return (
    <Box
      sx={{
        width: `${settings.logWidth}vw`,
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        top: 0,
        right: 0,
        pt: '80px',
        pl: '10px',
        pr: '10px',
      }}
    >
      <Typography color="textPrimary" variant="h6">
        Message Log
      </Typography>
      <Box
        sx={{
          height: '100vh',
          width: 2,
          cursor: 'grab',
          position: 'absolute',
          backgroundColor: '#000',
          left: 0,
          top: 0,
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          window.addEventListener('mouseup', handleMouseRelease);
          window.addEventListener('mousemove', handleMouseDrag);
        }}
      />
      <Box
        sx={{
          mt: '20px',
          pb: '20px',
          width: '100%',
          height: 'auto',
          overflow: 'scroll',
        }}
      >
        {logItems.map((logItem, index) => {
          const currentIndex = index;
          if (!showDebug && logItem.type === 'debug') {
            return null;
          }
          const severity = logItem.type === 'debug' ? 'info' : logItem.type;
          return (
            <Alert
              key={`logItem ${index}`}
              severity={severity}
              sx={{
                mb: '5px',
                width: '100%',
                position: 'relative',
                display: 'flex',
                '.MuiAlert-message': {
                  width: '100%',
                  minWidth: '50px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {logItem.message}
                {logItem.details && logItem.details.length > 0 && !expandedItems.includes(index) && (
                  <Box
                    onClick={() => setExpandedItems([...expandedItems, currentIndex])}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Typography variant="caption">
                      Show details
                      <ChevronDown sx={{ mb: -1.5, pb: 1 }} />
                    </Typography>
                  </Box>
                )}
                {logItem.details && logItem.details.length > 0 && expandedItems.includes(index) && (
                  <Box
                    onClick={() => setExpandedItems(expandedItems.filter((i) => i !== currentIndex))}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Typography variant="caption">
                      Hide details
                      <ChevronUp sx={{ mb: -1.5, pb: 1 }} />
                    </Typography>
                  </Box>
                )}
                {logItem.details && logItem.details.length > 0 && expandedItems.includes(index) && (
                  <Box
                    sx={{
                      background: theme.palette.background.default,
                      p: 2,
                      ml: -3,
                      mt: 2,
                      wordWrap: 'break-word',
                    }}
                  >
                    <Typography
                      sx={{
                        mb: 1,
                      }}
                      variant="body2"
                    >
                      Details:
                    </Typography>
                    {logItem.details.map((detail) => (
                      <Typography variant="body2">{detail}</Typography>
                    ))}
                  </Box>
                )}
              </Box>
            </Alert>
          );
        })}
      </Box>
    </Box>
  );
};

export default Log;
