import { useQuery, UseQueryResult } from 'react-query';
import { CustomerOrderItem } from 'src/types/CustomerOrderItem';
import { get, post, put, destroy } from '../utils';

export function createCustomerOrderItem(customerOrderId: string, sku: string, itemQuantity: number): Promise<CustomerOrderItem> {
  const body = {
    customerOrderId,
    sku,
    itemQuantity: itemQuantity.toString(),
  };
  return post<CustomerOrderItem>('customerorderitem', body);
}

export function deleteCustomerOrderItem(orderItemId: string): Promise<boolean> {
  return destroy(`customerorderitem/${orderItemId}`);
}

export function getCustomerOrderItems(orderId: string): Promise<CustomerOrderItem[]> {
  return get<CustomerOrderItem[]>('customerorderitem', {
    customerOrderId: orderId,
  });
}

export function updateCustomerOrderItem(orderItemId: string, properties: Partial<CustomerOrderItem>) {
  const body = {
    id: orderItemId,
    ...properties,
  };
  return put<CustomerOrderItem>(`customerorderitem/${orderItemId}`, body);
}

export function useQueryCustomerOrderItems(orderId: string): UseQueryResult<CustomerOrderItem[], Error> {
  return useQuery(['CustomerOrderItems', orderId], () => getCustomerOrderItems(orderId));
}
