import { Alert, Button, Dialog, Divider, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useGetXeroTenantStatus, useSetXeroTenantClientCredentialsMutation } from "src/lib/tether-microservices/routes/xero";
import { XeroTenant } from "src/types/XeroTenant";
import Label from "../Label";
import LoadingScreen from "../LoadingScreen";
import TetherButton from "../TetherButton";

const useXeroAuthUrl = (xeroTenant: XeroTenant) => {
  /** host hardcoded as it needs to match backend and xero redirect url; tesseract.tether.co.nz will not work */
  const redirectUrl = `https://tesseract.tetherhq.com/tools/xero-settings/auth-redirect`

  return `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${xeroTenant.clientId}&redirect_uri=${redirectUrl}&scope=offline_access accounting.transactions accounting.contacts.read accounting.settings.read&state=${xeroTenant.id}`
}

interface XeroTenantAccessKeyModalProps {
  xeroTenant: XeroTenant;
  isOpen: boolean;
  onClose: () => void;
}

export default function XeroTenantAccessKeyModal({ xeroTenant, isOpen, onClose }: XeroTenantAccessKeyModalProps) {
  const authUrl = useXeroAuthUrl(xeroTenant);
  const clientCredentialsMutation = useSetXeroTenantClientCredentialsMutation();

  const [clientId, setClientId] = useState<string>(xeroTenant.clientId);
  const [clientSecret, setClientSecret] = useState<string>('*****');

  const { data: status, isLoading: statusLoading, error: statusError } = useGetXeroTenantStatus(xeroTenant.id, { refetchOnWindowFocus: true });

  const saveClientCredentials = async () => {
    await clientCredentialsMutation.mutateAsync({ xeroTenantId: xeroTenant.id, clientId, clientSecret });
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box p={3}>
        <Typography variant="h5">
          {xeroTenant.name}
        </Typography>
        <Typography variant="caption">{xeroTenant.description}</Typography>
        <Divider sx={{ marginY: 2 }} />
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6">Update Client Connection</Typography>
          <TextField value={clientId} label="Client ID" onChange={(e) => setClientId(e.target.value)} />
          <TextField value={clientSecret} label="Client Secret" onChange={(e) => setClientSecret(e.target.value)} />
          <TetherButton onClick={saveClientCredentials} variant="contained">Save</TetherButton>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <Box>
          <Box flexDirection="row" display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">Connection Status:</Typography>
            {statusLoading && <LoadingScreen sx={{ width: 25 }} />}
            {!statusLoading && statusError && (<Alert severity="error">Unable to load status info (likely a Tether issue, not a Xero one)</Alert>)}
            {!statusLoading && !statusError && (<Label color={status.success ? "success" : "error"}>{status.success ? "Active" : "Failed"}</Label>)}
          </Box>
          <Typography variant="caption">{status?.message}</Typography>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        <Box display="flex" justifyContent="flex-end" gap={1} >
          <Button color="secondary" variant="outlined" onClick={onClose}>Close</Button>
          <Button component={Link} to={authUrl} variant="contained" color="primary">
            Re-authorise Connection To Xero
          </Button>
        </Box>
      </Box>
    </Dialog>
  );

}