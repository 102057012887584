import {
  alpha,
  Autocomplete,
  Box,
  Checkbox,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { ScrollBarProps } from 'react-perfect-scrollbar';
import useLog from 'src/hooks/useLog';
import ChevronDown from 'src/icons/ChevronDown';
import ChevronUp from 'src/icons/ChevronUp';
import Download from 'src/icons/Download';
import Search from 'src/icons/Search';
import { generateCsv } from 'src/utils/generateCsv';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import CopyToClipboardButton from '../CopyToClipboardButton';
import LoadingScreen from '../LoadingScreen';
import Scrollbar from '../Scrollbar';
import TetherButton from '../TetherButton';
import TetherTableColumn, { DisplayType, getColumnLabel } from './TetherTableColumn';

interface TetherTableProps<T> extends ScrollBarProps {
  loading?: boolean;
  data: T[];
  searchable?: boolean;
  miniSearch?: boolean;
  columns: TetherTableColumn<T>[];
  rowRenderKeyAttribute: keyof T;
  tableId?: string; // If using mutliple tables for potentially clashing data on the same page, add a unique ID
  onRowClick?: (displayObject: T) => void;
  getRowNavigationRoute?: (displayObject: T) => string;
  rowActions?: (displayObject: T) => JSX.Element;
  headerActions?: JSX.Element;
  disablePagination?: boolean;
  emptyText?: string;
  enableColumnSelection?: boolean;
  enableColumnGroups?: boolean;
  stickyHeader?: boolean;
  enableCSVExport?: boolean;
  customBulkActions?: (selectedObjects: T[]) => JSX.Element;
  rowBackgroundColor?: (displayObject: T) => string;
  initialSortColumnIndex?: number;
  initialSortDirection?: 'asc' | 'desc';
  disableRowHover?: boolean;
}

function TetherTable<T>(props: TetherTableProps<T>) {
  const {
    loading,
    data,
    searchable,
    miniSearch,
    columns: initialColumns,
    rowRenderKeyAttribute,
    tableId = '',
    onRowClick,
    getRowNavigationRoute,
    rowActions,
    disablePagination,
    emptyText,
    enableColumnSelection,
    enableColumnGroups,
    stickyHeader,
    enableCSVExport,
    customBulkActions,
    rowBackgroundColor,
    initialSortColumnIndex,
    initialSortDirection,
    disableRowHover = false,
    ...other
  } = props;

  const columns = initialColumns.filter((c) => !c.disabled);

  const [sortColumnIndex, setSortColumnIndex] = useState<number>(initialSortColumnIndex || -1);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(initialSortDirection || 'asc');
  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(disablePagination ? Number.MAX_SAFE_INTEGER : 20);
  const [visibleColumns, setVisibleColumns] = useState<number[]>(
    columns.map((_, index) => index).filter((index) => !columns[index].hideByDefault)
  );
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const theme = useTheme();
  const log = useLog();

  const bulkActionsEnabled = enableCSVExport || !!customBulkActions;

  const updateSort = (index: number) => {
    if (sortColumnIndex !== index) {
      setSortColumnIndex(index);
      setSortDirection('asc');
    } else {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  let fiteredData = data;

  const getValue = (column: TetherTableColumn<T>, object: T) => {
    if (column.getValue) {
      return column.getValue(object);
    }

    return object[column.attribute];
  };

  const getValueAsString = (column: TetherTableColumn<T>, object: T): string => {
    const value = getValue(column, object);
    if (value === undefined) return '';
    return value.toString();
  };

  const getFormattedValue = (column: TetherTableColumn<T>, object: T) => {
    const value = getValue(column, object);

    switch (column.displayType) {
      case DisplayType.CURRENCY:
        return `$${parseFloat(`${value}`).toFixed(2)}`;
      case DisplayType.DATE:
        return value ? format(new Date(value as any), 'dd/MM/yyyy') : '';
      case DisplayType.DATETIME:
        return value ? format(new Date(value as any), 'dd/MM/yyyy - HH:mm') : '';
      default:
        return value;
    }
  };

  const getSubtextValue = (column: TetherTableColumn<T>, object: T) => {
    if (column.getSubtextValue) {
      return column.getSubtextValue(object);
    }

    return object[column.subTextAttribute];
  };

  if (sortColumnIndex >= 0) {
    const sortColumn = columns[sortColumnIndex < 0 || sortColumnIndex >= columns.length ? 0 : sortColumnIndex];
    fiteredData = fiteredData.sort((a, b) => {
      let result = 0;
      if (sortColumn.sort) {
        result = sortColumn.sort(a, b);
      } else {
        let compA: any = getValue(sortColumn, a);
        let compB: any = getValue(sortColumn, b);

        // Convert dates to milliseconds for sorting
        if (sortColumn.displayType && sortColumn.displayType.startsWith('date')) {
          compA = compA ? new Date(compA).getTime() : Number.MAX_SAFE_INTEGER;
          compB = compB ? new Date(compB).getTime() : Number.MAX_SAFE_INTEGER;
        }

        compA = typeof compA === 'string' ? compA.toLowerCase() : compA;
        compB = typeof compB === 'string' ? compB.toLowerCase() : compB;

        if (compA < compB) {
          result = -1;
        } else if (compB < compA) {
          result = 1;
        } else {
          result = 0;
        }
      }

      if (sortDirection === 'desc') {
        return 0 - result;
      }

      return result;
    });
  }

  fiteredData = fiteredData.filter((object) => {
    const columnValues = columns.reduce(
      (acc, column) => acc + getValue(column, object) + (column.subTextAttribute ? getSubtextValue(column, object) : ''),
      ''
    );
    const queries = query.split('+').filter((q) => !!q.trim());

    return (
      queries.length === 0 ||
      !!queries.find((q) => {
        const normalisedColumn = columnValues
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .toLowerCase();
        const normalisedSearch = q
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .toLowerCase();
        return normalisedColumn.includes(normalisedSearch);
      })
    );
  });

  const displayObjects = fiteredData.slice(page * limit, page * limit + limit);

  const isColumnVisible = (column: TetherTableColumn<T> | number) =>
    visibleColumns.includes(typeof column === 'number' ? column : columns.indexOf(column));

  const columnGroups = [];
  if (enableColumnGroups) {
    // Make header color solid to make scroll behind work but body with opacity to allow cursor highlight to show through
    const colors = [alpha(theme.palette.grey[100], 0.5), alpha(theme.palette.grey[200], 0.5)];
    const headerColors = [alpha(theme.palette.grey[50], 1), alpha(theme.palette.grey[100], 1)];
    let lastColor = colors[1];
    let lastHeaderColor = headerColors[1];

    columns.forEach((column) => {
      if (isColumnVisible(column)) {
        if (columnGroups.length > 0 && columnGroups[columnGroups.length - 1].name === column.groupName) {
          columnGroups[columnGroups.length - 1].count++;
        } else {
          lastColor = lastColor === colors[0] ? colors[1] : colors[0]; // alternating grey backgrounds
          lastHeaderColor = lastHeaderColor === headerColors[0] ? headerColors[1] : headerColors[0];
          columnGroups.push({
            name: column.groupName,
            count: 1,
            color: column.groupName ? lastColor : 'none', // for ungrouped columns leave background empty
            headerColor: column.groupName ? lastHeaderColor : 'none',
          });
        }
      }
    });
  }

  const getColumnGroup = (column: TetherTableColumn<T> | number) => {
    const index = typeof column === 'number' ? column : columns.filter((c) => isColumnVisible(c)).indexOf(column);
    let total = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const col of columnGroups) {
      total += col.count;
      if (index < total) {
        return col;
      }
    }

    // Fallback to an empty group
    return {};
  };

  // When the displayed selection changes the selected rows are not changed to allow retaining selection
  // while modifying displayed data subset, so filter by what's currently displayed
  const visibleSelectedRows = selectedRows.filter((r) => fiteredData.includes(r));

  return (
    <Box className="tether-table">
      {/* Search & column selection header */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {searchable && (
          <Box
            sx={{
              m: 1,
              minWidth: miniSearch ? undefined : '30%',
              flex: miniSearch ? undefined : 1,
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search (use '+' to search multiple terms at once)"
              value={query}
              variant="outlined"
            />
          </Box>
        )}
        {enableColumnSelection && (
          <Autocomplete
            sx={{
              m: 1,
              minWidth: '48%',
              flex: 1,
            }}
            multiple
            limitTags={2}
            options={columns.map((_, index) => index)}
            getOptionLabel={(column) => getColumnLabel(columns[column])}
            value={visibleColumns}
            disableCloseOnSelect
            onChange={(_, selected) => {
              const items = selected.filter((s) => typeof s !== 'string') as number[];
              setVisibleColumns(items);
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Show/Hide Columns" />}
          />
        )}
        {bulkActionsEnabled && (
          <Box
            sx={{
              m: 1,
              minWidth: '48%',
              flex: 1,
              position: 'relative',
            }}
          >
            <TextField
              variant="outlined"
              label="Bulk Actions"
              disabled
              multiline
              value=" "
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                '*': {
                  cursor: 'default',
                  // height: '100%',
                },
                div: {
                  display: 'block',
                  height: '100%',
                },
                label: { color: theme.palette.text.secondary },
              }}
            />
            <Box
              sx={{
                pt: '10px',
                pb: 1,
                pr: 1,
                pl: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" sx={{ pr: 1 }}>
                {`${visibleSelectedRows.length} of ${fiteredData.length} items selected `}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  rowGap: 1,
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end',
                }}
              >
                <TetherButton
                  variant="contained"
                  startIcon={<Download fontSize="small" />}
                  disabled={!visibleSelectedRows.length}
                  onClick={() => {
                    generateCsv(
                      visibleSelectedRows,
                      columns.map((c) => c.attribute as string),
                      'report-export.csv'
                    );
                  }}
                >
                  {`${visibleSelectedRows.length} of ${fiteredData.length} items selected `}
                </TetherButton>
                <Box
                  sx={{
                    display: 'flex',
                    rowGap: 1,
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end',
                  }}
                >
                  <TetherButton
                    variant="contained"
                    startIcon={<Download fontSize="small" />}
                    disabled={!visibleSelectedRows.length}
                    onClick={() => {
                      const rows = visibleSelectedRows.map((r) => {
                        const result = {};
                        Object.assign(
                          result,
                          ...columns.map((c) => ({
                            [c.label || c.attribute]: getValue(c, r),
                          }))
                        );
                        return result;
                      });
                      generateCsv(
                        rows,
                        columns.map((c) => (c.label || c.attribute) as string),
                        'report-export.csv'
                      );
                    }}
                  >
                    Download as CSV
                  </TetherButton>
                  {customBulkActions && customBulkActions(visibleSelectedRows)}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Scrollbar {...other}>
        {/* Data table */}
        <TableContainer
          sx={{
            maxHeight: '80%',
            width: '100%',
          }}
        >
          <Table stickyHeader={stickyHeader} sx={{ width: '100%' }}>
            <TableHead
              sx={{
                '.MuiTableCell-stickyHeader': {
                  background: 'none',
                },
                borderBottomColor: 'black',
              }}
            >
              {/* Column Group Headers */}
              {enableColumnGroups && (
                <TableRow>
                  {bulkActionsEnabled && <TableCell />}
                  {columnGroups.map((group, i) => (
                    <TableCell
                      key={i}
                      colSpan={group.count}
                      sx={{
                        '&.MuiTableCell-stickyHeader': {
                          position: 'relative',
                          background: group.headerColor,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          textAlign: 'center',
                        }}
                      >
                        {group.name}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              )}

              {/* Column Headers */}
              <TableRow>
                {bulkActionsEnabled && (
                  <TableCell
                    sx={{
                      '&.MuiTableCell-stickyHeader': {
                        background: theme.palette.background.paper,
                      },
                    }}
                  >
                    <Checkbox
                      checked={!fiteredData.find((o) => !selectedRows.includes(o))}
                      onChange={(_, checked) => {
                        if (checked) {
                          setSelectedRows([...new Set([...selectedRows, ...fiteredData])]);
                        } else {
                          setSelectedRows(selectedRows.filter((r) => !fiteredData.includes(r)));
                        }
                      }}
                    />
                  </TableCell>
                )}
                {columns.map((column, index) => {
                  if (!isColumnVisible(column)) {
                    return null;
                  }

                  const columnLabel = getColumnLabel(column);

                  if (column.sortable === undefined || column.sortable) {
                    return (
                      <TableCell
                        key={index}
                        onClick={() => updateSort(index)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                          '&.MuiTableCell-stickyHeader': {
                            background: getColumnGroup(column).headerColor,
                            left: 'auto',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant="body2" color="textPrimary">
                            {columnLabel}
                          </Typography>
                          {index === sortColumnIndex && sortDirection === 'asc' && <ChevronUp color="action" />}
                          {index === sortColumnIndex && sortDirection === 'desc' && <ChevronDown color="action" />}
                        </Box>
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell>
                      <Typography variant="body2" color="textPrimary">
                        {columnLabel}
                      </Typography>
                    </TableCell>
                  );
                })}
                {rowActions && <TableCell align="right">Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                displayObjects.map((displayObject) => {
                  const currentDisplayObject = displayObject;

                  return (
                    <TableRow
                      key={`${displayObject[rowRenderKeyAttribute]} ${tableId}`}
                      component={getRowNavigationRoute ? RouterLink : null}
                      to={getRowNavigationRoute ? getRowNavigationRoute(displayObject) : null}
                      onClick={() => (onRowClick ? onRowClick(displayObject) : null)}
                      sx={{
                        cursor: onRowClick || getRowNavigationRoute ? 'pointer' : 'default',
                        backgroundColor: rowBackgroundColor ? rowBackgroundColor(currentDisplayObject) : 'none',
                        '&:hover': {
                          backgroundColor: disableRowHover ? 'none' : alpha(theme.palette.primary.main, 0.05),
                        },
                        textDecoration: 'none',
                        '.MuiTableCell-root': {
                          padding: '6px 16px',
                        },
                      }}
                    >
                      {bulkActionsEnabled && (
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.includes(currentDisplayObject)}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(_, checked) => {
                              if (checked) {
                                setSelectedRows([...selectedRows, currentDisplayObject]);
                              } else {
                                setSelectedRows(selectedRows.filter((r) => r !== currentDisplayObject));
                              }
                            }}
                          />
                        </TableCell>
                      )}

                      {columns.map((column, index) => {
                        if (!isColumnVisible(column)) {
                          return null;
                        }

                        return (
                          <TableCell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${currentDisplayObject[rowRenderKeyAttribute]} ${tableId} column ${index}`}
                            sx={{
                              backgroundColor: getColumnGroup(column).color,
                              whiteSpace: column.disableWrap ? 'nowrap' : 'normal',
                            }}
                          >
                            {column.renderOverride && column.renderOverride(getValue(column, currentDisplayObject), currentDisplayObject)}
                            {!column.renderOverride && (
                              <>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography variant="body2" color="textPrimary">
                                    {(getFormattedValue(column, currentDisplayObject) as unknown) as any}
                                  </Typography>
                                  {column.copyToClipboard && (
                                    <CopyToClipboardButton text={getValueAsString(column, currentDisplayObject)} log={log} />
                                  )}
                                </Box>
                                {column.subTextAttribute && (
                                  <Typography variant="body2" color="textSecondary">
                                    {(getSubtextValue(column, currentDisplayObject) as unknown) as any}
                                  </Typography>
                                )}
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                      {rowActions && <TableCell align="right">{rowActions(currentDisplayObject)}</TableCell>}
                    </TableRow>
                  );
                })}
              {!loading && displayObjects.length === 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1}>
                    <Typography
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                        p: 3,
                      }}
                      color="textSecondary"
                    >
                      {emptyText || 'There are no items to display'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1}>
                    <LoadingScreen sx={{ width: '75px' }} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {!disablePagination && (
        <TablePagination
          component="div"
          count={fiteredData.length}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => setLimit(parseInt(e.target.value, 10))}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[20, 50, 200, 1000, 5000]}
        />
      )}
    </Box>
  );
}

export default TetherTable;
