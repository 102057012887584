import { LogContextValue } from 'src/contexts/LogContext';

const copyToClipboard = async (toCopy: string, log: LogContextValue = null) => {
  if (!navigator.clipboard) {
    if (log) {
      log.error('Unable to copy to clipboard. Clipboard undefined. Are you on HTTPS?');
    }
    return;
  }

  try {
    await navigator.clipboard.writeText(toCopy);
    if (log) {
      log.success(`'${toCopy}' copied to clipboard`);
    }
  } catch (err) {
    if (log) {
      log.error('Unable to copy to clipboard', err);
    }
  }
};

export default copyToClipboard;
