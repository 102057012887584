import { createContext, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { PreviewableEntity } from 'src/types/PreviewableEntity';
import { PreviewableEntityType } from 'src/components/EntityViewWithPreview';
import TetherApi from 'src/lib/tether-microservices/TetherApi';

const getEntityKey = (type: PreviewableEntityType, id: string) => `${type}${id}`;

interface State {
  entityPromises: { [key: string]: Promise<PreviewableEntity> };
}

interface EntityPreviewContextValue extends State {
  getEntity(type: PreviewableEntityType, id: string): Promise<PreviewableEntity>;
}

interface EntityPreviewProps {
  children: ReactNode;
}

type StoreEntityAction = {
  type: 'STORE_ENTITY';
  payload: {
    type: PreviewableEntityType;
    id: string;
    promise: Promise<PreviewableEntity>;
  };
};

type Action = StoreEntityAction;

const initialState: State = {
  entityPromises: {},
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  STORE_ENTITY: (state: State, action: StoreEntityAction): State => {
    const { type, id, promise } = action.payload;
    const entityPromises = { ...state.entityPromises };
    entityPromises[getEntityKey(type, id)] = promise;

    return {
      ...state,
      entityPromises,
    };
  },
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const EntityPreviewContext = createContext<EntityPreviewContextValue>({
  ...initialState,
  getEntity: () => new Promise<PreviewableEntity>(null),
});

export const EntityPreviewProvider: FC<EntityPreviewProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getEntity = (type: PreviewableEntityType, id: string): Promise<PreviewableEntity> => {
    let promise;

    const existing = state.entityPromises[getEntityKey(type, id)];

    if (existing) {
      return existing;
    }

    switch (type) {
      case 'customer':
        promise = TetherApi.getCustomerById(id);
        break;
      case 'device':
        promise = TetherApi.getDevice(id);
        break;
      case 'installation':
        promise = TetherApi.getInstallationById(id);
        break;
      case 'installationLocation':
        promise = TetherApi.getInstallationLocationById(id);
        break;
      case 'organisation':
        promise = TetherApi.getOrganisationById(id);
        break;
      default:
        throw new Error(`Type ${type} not supported`);
    }

    dispatch({
      type: 'STORE_ENTITY',
      payload: {
        type,
        id,
        promise,
      },
    });

    return promise;
  };

  return (
    <EntityPreviewContext.Provider
      value={{
        ...state,
        getEntity,
      }}
    >
      {children}
    </EntityPreviewContext.Provider>
  );
};

EntityPreviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EntityPreviewContext;
