import type { FC } from 'react';
import type { Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: FC<LogoProps> = (props) => (
  <>
    <Box boxSizing="border-box" {...props}>
      <title>Tether</title>
      <img width="100%" src="/static/logo.png" alt="Tether" />
    </Box>
  </>
);

export default Logo;
