import { Device } from 'src/types/Device';
import { New } from 'src/lib/dbHelper';
import { PriceSetItemType } from 'src/types/PriceSetItemType';
import { UseQueryResult, useQuery } from 'react-query';
import { QueryParams, get, post } from '../utils';

enum QueryKeys {
  PriceSetItemTypeSearch = 'PriceSetItemTypeSearch',
}

export async function getPriceSetItemType(sku: string): Promise<Device> {
  return get<PriceSetItemType>(`pricesetitemtype/${sku}`);
}

export async function createPriceSetItemType(item: New<PriceSetItemType>): Promise<PriceSetItemType> {
  return post<PriceSetItemType>('pricesetitemtype', item);
}

export interface PriceSetItemTypeQuery extends QueryParams {
  sku?: string;
  name?: string;
  description?: string;
  textQuery?: string;
}

export async function searchPriceSetItemTypes(query?: PriceSetItemTypeQuery): Promise<PriceSetItemType[]> {
  return get<PriceSetItemType[]>('pricesetitemtype', query || {});
}

export function useSearchPriceSetItemTypes(query?: PriceSetItemTypeQuery): UseQueryResult<PriceSetItemType[], Error> {
  return useQuery([QueryKeys.PriceSetItemTypeSearch, query], () => searchPriceSetItemTypes(query));
}
