import { createContext, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import ConfirmDialog from 'src/components/ConfirmDialog';
import AlertDialog from 'src/components/AlertDialog';
import CustomDialog from 'src/components/CustomDialog';

interface DialogOptions {
  title?: string;
  subtitle?: string;
  onSubmit?: () => any;
  variant?: 'alert' | 'confirm' | 'custom';
  customDialog?: CustomDialog;
}

interface State {
  loading: boolean;
  options: DialogOptions;
  open: boolean;
}

interface DialogContextValue extends State {
  loading;
  options: DialogOptions;
  open;
  showDialog: (DialogOptions) => void;
  dismissDialog: () => void;
}

interface DialogProviderProps {
  children: ReactNode;
}

type ShowDialogAction = {
  type: 'SHOW_DIALOG';
  payload: {
    options;
  };
};

type DismissDialogAction = {
  type: 'DISMISS_DIALOG';
};

type SubmitDialogAction = {
  type: 'SUBMIT_DIALOG';
};

type LoadingCompleteAction = {
  type: 'LOADING_COMPLETE';
};

type Action = ShowDialogAction | DismissDialogAction | SubmitDialogAction | LoadingCompleteAction;

const initialState: State = {
  loading: false,
  options: {},
  open: false,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  SHOW_DIALOG: (state: State, action: ShowDialogAction): State => {
    const { options } = action.payload;

    return {
      ...state,
      options,
      open: true,
    };
  },
  DISMISS_DIALOG: (state: State): State => ({
    ...state,
    loading: false,
    options: {},
    open: false,
  }),
  SUBMIT_DIALOG: (state: State): State => ({
    ...state,
    loading: true,
  }),
  LOADING_COMPLETE: (state: State): State => ({
    ...state,
    loading: false,
    options: {},
    open: false,
  }),
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const DialogContext = createContext<DialogContextValue>({
  ...initialState,
  loading: false,
  options: {},
  open: false,
  showDialog: () => {},
  dismissDialog: () => {},
});

export const DialogProvider: FC<DialogProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { open } = state;
  const { title, subtitle, onSubmit, customDialog } = state.options;

  const showDialog = async (options: DialogOptions): Promise<void> => {
    dispatch({
      type: 'SHOW_DIALOG',
      payload: { options },
    });
  };

  const dismissDialog = async (): Promise<void> => {
    dispatch({
      type: 'DISMISS_DIALOG',
    });
  };

  const handleSubmit = async () => {
    dispatch({ type: 'SUBMIT_DIALOG' });
    await Promise.resolve(onSubmit());
    dispatch({ type: 'LOADING_COMPLETE' });
  };

  return (
    <DialogContext.Provider
      value={{
        ...state,
        showDialog,
        dismissDialog,
      }}
    >
      <>
        {open && state.options.variant === 'confirm' && (
          <ConfirmDialog
            title={title}
            subtitle={subtitle}
            onConfirm={handleSubmit}
            onClose={() => dispatch({ type: 'DISMISS_DIALOG' })}
            open={open}
          />
        )}
        {open && state.options.variant === 'custom' && customDialog}
        {open && state.options.variant === 'alert' && (
          <AlertDialog title={title} subtitle={subtitle} onClose={() => dispatch({ type: 'DISMISS_DIALOG' })} open={open} />
        )}
        {children}
      </>
    </DialogContext.Provider>
  );
};

DialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DialogContext;
