import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { CustomerInvoice } from 'src/types/CustomerInvoice';
import { CustomerInvoiceLineItem } from 'src/types/CustomerInvoiceLineItem';
import { destroy, get, post, put } from '../utils';

export function getCustomerInvoiceById(invoiceId: string, includeDeleted?: boolean): Promise<CustomerInvoice> {
  return get<CustomerInvoice>(`customerinvoice/${invoiceId}${includeDeleted ? '?includeDeleted=true' : ''}`);
}
export interface CustomerInvoiceSearchParams {
  customerId?: string;
  createdFromCustomerOrderId?: string;
  createdFromDeviceContractId?: string;
  invoiceDateFrom?: string;
  invoiceDateTo?: string;
  isFromDeviceContract?: boolean;
  onlyDrafts?: boolean;
  includeDeleted?: boolean;
  includeVoided?: boolean;
  textQuery?: string;
}

export function searchCustomerInvoices(params: CustomerInvoiceSearchParams): Promise<CustomerInvoice[]> {
  return get<CustomerInvoice[]>('customerinvoice', { ...params });
}

export function getCustomerInvoiceLineItems(invoiceId: string): Promise<CustomerInvoiceLineItem[]> {
  return get<CustomerInvoiceLineItem[]>(`customerinvoice/${invoiceId}/lineItems`);
}

export function createCustomerInvoice(invoice: Partial<CustomerInvoice>): Promise<CustomerInvoice> {
  const body = {
    ...invoice,
  };
  return post<CustomerInvoice>('customerinvoice', body);
}

export function markInvoiceAsCompleted(invoiceId: string): Promise<void> {
  return put<void>(`CustomerInvoice/${invoiceId}/markAsCompleted`, {});
}

export function sendInvoice(invoiceId: string): Promise<void> {
  return put<void>(`CustomerInvoice/${invoiceId}/send`, {});
}

export function markInvoiceAsSent(invoiceId: string): Promise<void> {
  return put<void>(`CustomerInvoice/${invoiceId}/markAsSent`, {});
}

export function voidInvoice(invoiceId: string): Promise<void> {
  return put<void>(`CustomerInvoice/${invoiceId}/markAsVoided`, {});
}

export function deleteInvoice(invoiceId: string): Promise<void> {
  return destroy(`CustomerInvoice/${invoiceId}`, {});
}

export function updateCustomerInvoice(invoiceId: string, properties: object) {
  const body = {
    id: invoiceId,
    ...properties,
  };
  return put<CustomerInvoice>(`customerinvoice/${invoiceId}`, body);
}

export function resyncCustomerInvoiceToXero(invoiceId: string) {
  return post<CustomerInvoice>(`customerinvoice/${invoiceId}/resyncXero`);
}

export function updateCustomerInvoiceLineItems(invoiceId: string, lineItems: Partial<CustomerInvoiceLineItem>[]) {
  const body = {
    lineItems,
  };
  return put<CustomerInvoice>(`customerinvoice/${invoiceId}/lineItems`, body);
}

export function useQueryCustomerInvoices(
  params: CustomerInvoiceSearchParams,
  options: UseQueryOptions<CustomerInvoice[], Error>
): UseQueryResult<CustomerInvoice[], Error> {
  return useQuery(['CustomerInvoice', ...Object.values(params)], () => searchCustomerInvoices(params), options);
}

export function useQueryCustomerInvoicesByOrderId(
  orderId: string,
  options?: UseQueryOptions<CustomerInvoice[], Error>
): UseQueryResult<CustomerInvoice[], Error> {
  return useQuery(['CustomerInvoice', orderId], () => searchCustomerInvoices({ createdFromCustomerOrderId: orderId }), options);
}

export function useQueryCustomerInvoiceById(invoiceId: string, includeDeleted?: boolean): UseQueryResult<CustomerInvoice, Error> {
  return useQuery(['CustomerInvoice', invoiceId], () => getCustomerInvoiceById(invoiceId, includeDeleted));
}

export function useQueryCustomerInvoiceLineItems(invoiceId: string): UseQueryResult<CustomerInvoiceLineItem[], Error> {
  return useQuery(['CustomerInvoiceLineItem', invoiceId], () => getCustomerInvoiceLineItems(invoiceId));
}

export function useQueryCustomrInvoiceBillingEmail(
  invoiceId: string,
  options: UseQueryOptions<string, Error>
): UseQueryResult<string, Error> {
  return useQuery(['CustomerInvoiceBillingEmail', invoiceId], () => get<string>(`customerinvoice/${invoiceId}/billingEmail`), options);
}

export function generateInvoiceFromCustomerOrder(customerOrderId): Promise<CustomerInvoice> {
  const body = {
    customerOrderId,
  };
  return post<CustomerInvoice>('customerinvoice/generateFromCustomerOrder', body);
}

export function generateInvoiceFromDeviceContract(deviceContractId): Promise<CustomerInvoice> {
  const body = {
    deviceContractId,
  };
  return post<CustomerInvoice>('customerinvoice/generateFromDeviceContract', body);
}
