import { createContext, FC, ReactNode, useEffect, useReducer } from 'react';
import TetherApi from 'src/lib/tether-microservices/TetherApi';
import { Customer } from 'src/types/Customer';
import { CustomerOrder } from 'src/types/CustomerOrder';
import { CustomerOrderItem } from 'src/types/CustomerOrderItem';
import { GenericDevice } from 'src/types/GenericDevice';
import { PriceSet } from 'src/types/PriceSet';
import { PriceSetItem } from 'src/types/PriceSetItem';
import { QuickCircuitShipment } from 'src/types/QuickCircuitShipment';
import { QuickCircuitShipmentDevice } from 'src/types/QuickCircuitShipmentDevice';

interface State {
  customer: Customer;
  priceSet: PriceSet;
  priceSetItems: PriceSetItem[];
  devices: GenericDevice[];
  order: CustomerOrder;
  orderItems: CustomerOrderItem[];
  shipments: QuickCircuitShipment[];
  shipmentDevices: QuickCircuitShipmentDevice[];
  loading: boolean;
  error: string;
}

interface CustomerOrderContextValue extends State {
  addShipment: (shipment: QuickCircuitShipment) => void;
  addDevice: (shipmentDevice: QuickCircuitShipmentDevice, device: GenericDevice) => void;
  addOrderItem: (orderItem: CustomerOrderItem) => void;
  removeDevice: (deviceId: string) => void;
  removeOrderItem: (orderItemId: string) => void;
  removeShipment: (shipmentId: string) => void;
  updateShipment: (shipment: QuickCircuitShipment) => void;
  updateCustomer: (customerItem: Customer) => void;
  updateOrder: (orderItem: CustomerOrder) => void;
  updateOrderItem: (item: CustomerOrderItem) => void;
}

interface CustomerOrderProviderProps {
  orderId: string;
  children: ReactNode;
}

const initialState: State = {
  customer: null,
  priceSet: null,
  priceSetItems: [],
  devices: [],
  order: null,
  orderItems: [],
  shipments: [],
  shipmentDevices: [],
  loading: true,
  error: '',
};

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    customer: Customer;
    priceSet: PriceSet;
    priceSetItems: PriceSetItem[];
    devices: GenericDevice[];
    order: CustomerOrder;
    orderItems: CustomerOrderItem[];
    shipments: QuickCircuitShipment[];
    shipmentDevices: QuickCircuitShipmentDevice[];
  };
};

type AddShipmentAction = {
  type: 'ADD_SHIPMENT';
  payload: {
    shipment: QuickCircuitShipment;
  };
};

type AddDeviceAction = {
  type: 'ADD_DEVICE';
  payload: {
    shipmentDevice: QuickCircuitShipmentDevice;
    device: GenericDevice;
  };
};

type AddOrderItemAction = {
  type: 'ADD_ORDER_ITEM';
  payload: {
    orderItem: CustomerOrderItem;
  };
};

type ErrorAction = {
  type: 'ERROR';
  payload: {
    error: string;
  };
};

type RemoveDeviceAction = {
  type: 'REMOVE_DEVICE';
  payload: {
    deviceId: string;
  };
};

type RemoveOrderItemAction = {
  type: 'REMOVE_ORDER_ITEM';
  payload: {
    orderItemId: string;
  };
};

type RemoveShipmentAction = {
  type: 'REMOVE_SHIPMENT';
  payload: {
    shipmentId: string;
  };
};

type UpdateCustomerAction = {
  type: 'UPDATE_CUSTOMER';
  payload: {
    customer: Customer;
  };
};

type UpdateOrderAction = {
  type: 'UPDATE_ORDER';
  payload: {
    order: CustomerOrder;
  };
};

type UpdateOrderItemAction = {
  type: 'UPDATE_ORDER_ITEM';
  payload: {
    orderItem: CustomerOrderItem;
  };
};

type UpdateShipmentAction = {
  type: 'UPDATE_SHIPMENT';
  payload: {
    shipment: QuickCircuitShipment;
  };
};

type Action =
  | InitializeAction
  | AddShipmentAction
  | AddDeviceAction
  | AddOrderItemAction
  | ErrorAction
  | RemoveDeviceAction
  | RemoveOrderItemAction
  | RemoveShipmentAction
  | UpdateCustomerAction
  | UpdateOrderAction
  | UpdateOrderItemAction
  | UpdateShipmentAction;

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { customer, devices, order, orderItems, shipments, shipmentDevices, priceSet, priceSetItems } = action.payload;
    return {
      ...state,
      customer,
      devices,
      order,
      orderItems,
      shipments,
      shipmentDevices,
      priceSet,
      priceSetItems,
      loading: false,
    };
  },
  ADD_SHIPMENT: (state: State, action: AddShipmentAction): State => {
    const shipments = [...state.shipments];
    shipments.push(action.payload.shipment);

    return {
      ...state,
      shipments,
    };
  },
  ADD_DEVICE: (state: State, action: AddDeviceAction): State => {
    const shipmentDevices = [...state.shipmentDevices];
    const devices = [...state.devices];
    shipmentDevices.push(action.payload.shipmentDevice);
    devices.push(action.payload.device);

    return {
      ...state,
      devices,
      shipmentDevices,
    };
  },
  ADD_ORDER_ITEM: (state: State, action: AddOrderItemAction): State => {
    const orderItems = [...state.orderItems];
    orderItems.push(action.payload.orderItem);

    return {
      ...state,
      orderItems,
    };
  },
  ERROR: (state: State, action: ErrorAction): State => {
    const { error } = action.payload;

    return {
      ...state,
      error,
    };
  },
  REMOVE_DEVICE: (state: State, action: RemoveDeviceAction): State => {
    const shipmentDevices = state.shipmentDevices.filter((d) => d.deviceId !== action.payload.deviceId);
    const devices = state.devices.filter((d) => d.id !== action.payload.deviceId);

    return {
      ...state,
      shipmentDevices,
      devices,
    };
  },
  REMOVE_ORDER_ITEM: (state: State, action: RemoveOrderItemAction): State => {
    const orderItems = state.orderItems.filter((o) => o.id !== action.payload.orderItemId);

    return {
      ...state,
      orderItems,
    };
  },
  REMOVE_SHIPMENT: (state: State, action: RemoveShipmentAction): State => {
    const shipments = state.shipments.filter((s) => s.id !== action.payload.shipmentId);

    return {
      ...state,
      shipments,
    };
  },
  UPDATE_CUSTOMER: (state: State, action: UpdateCustomerAction): State => {
    const { customer } = action.payload;

    return {
      ...state,
      customer,
    };
  },
  UPDATE_ORDER: (state: State, action: UpdateOrderAction): State => {
    const { order } = action.payload;

    return {
      ...state,
      order,
    };
  },
  UPDATE_ORDER_ITEM: (state: State, action: UpdateOrderItemAction): State => {
    const { orderItem } = action.payload;
    const orderItems = [...state.orderItems];
    orderItems[orderItems.findIndex((item) => item.id === orderItem.id)] = orderItem;

    return {
      ...state,
      orderItems,
    };
  },
  UPDATE_SHIPMENT: (state: State, action: UpdateShipmentAction): State => {
    const { shipment } = action.payload;
    const shipments = state.shipments.filter((s) => s.id !== shipment.id);
    shipments.push(shipment);

    return {
      ...state,
      shipments,
    };
  },
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const CustomerOrderContext = createContext<CustomerOrderContextValue>({
  ...initialState,
  addShipment: () => {},
  addDevice: () => {},
  addOrderItem: () => {},
  removeDevice: () => {},
  removeOrderItem: () => {},
  removeShipment: () => {},
  updateCustomer: () => {},
  updateOrder: () => {},
  updateOrderItem: () => {},
  updateShipment: () => {},
});

export const CustomerOrderProvider: FC<CustomerOrderProviderProps> = (props) => {
  const { children, orderId } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const promises = [];
        const order = await TetherApi.getCustomerOrderById(orderId);
        promises.push(TetherApi.getCustomerById(order.customerId));
        promises.push(TetherApi.getCustomerOrderItems(orderId));
        const shipments = await TetherApi.getQuickCircuitShipmentsForOrder(orderId);
        const shipmentDevices = [].concat(
          ...(await Promise.all(shipments.map((shipment) => TetherApi.getQuickCircuitShipmentDevices(shipment.id))))
        );

        // a device may be deleted, and then one of these calls would fail :(
        const tryGetAllDevicesPromise: Promise<GenericDevice[]> = new Promise((resolve, reject) => {
          const devicePromises = shipmentDevices.map((device) => {
            const foundDevice = device.getDevice().catch((err) => {
              console.error(err);

              // return a placeholder device
              const PLACEHOLDER_TEXT = 'DEVICE NOT FOUND. ID=' + device.deviceId;
              const placeholderDevice: GenericDevice = {
                id: device.deviceId,
                serialNumber: PLACEHOLDER_TEXT,
                sku: PLACEHOLDER_TEXT,
                createdAt: '',
                updatedAt: '',
                deletedAt: '',

                getDeviceType: () => PLACEHOLDER_TEXT,
                getFriendlyDeviceType: () => PLACEHOLDER_TEXT,
                getPhysicalLabel: () => PLACEHOLDER_TEXT,
              };

              return placeholderDevice;
            });

            return foundDevice as GenericDevice;
          });

          Promise.all(devicePromises).then((results) => {
            resolve(results);
          });
        });

        // Load price set data
        const { priceSetId } = await promises[0];
        let priceSet = priceSetId ? await TetherApi.getPriceSet(priceSetId) : null;
        if (!priceSet) {
          [priceSet] = await TetherApi.searchPriceSets({ isDefault: true });
        }
        const priceSetItems = await TetherApi.getPriceSetItems(priceSet.id);

        const results = await Promise.all(promises);
        const allDevices = await tryGetAllDevicesPromise;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            customer: results.shift(),
            priceSet,
            priceSetItems,
            order,
            orderItems: results.shift(),
            shipments,
            shipmentDevices: [...shipmentDevices],
            devices: allDevices,
          },
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'ERROR',
          payload: {
            error: err.message,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addShipment = (shipment: QuickCircuitShipment) =>
    dispatch({
      type: 'ADD_SHIPMENT',
      payload: {
        shipment,
      },
    });

  const addDevice = (shipmentDevice: QuickCircuitShipmentDevice, device: GenericDevice) =>
    dispatch({
      type: 'ADD_DEVICE',
      payload: {
        device,
        shipmentDevice,
      },
    });

  const addOrderItem = (orderItem: CustomerOrderItem) =>
    dispatch({
      type: 'ADD_ORDER_ITEM',
      payload: {
        orderItem,
      },
    });

  const removeDevice = (deviceId: string) =>
    dispatch({
      type: 'REMOVE_DEVICE',
      payload: {
        deviceId,
      },
    });

  const removeOrderItem = (orderItemId: string) =>
    dispatch({
      type: 'REMOVE_ORDER_ITEM',
      payload: {
        orderItemId,
      },
    });

  const removeShipment = (shipmentId: string) =>
    dispatch({
      type: 'REMOVE_SHIPMENT',
      payload: {
        shipmentId,
      },
    });

  const updateCustomer = (customer: Customer) =>
    dispatch({
      type: 'UPDATE_CUSTOMER',
      payload: {
        customer,
      },
    });

  const updateOrder = (order: CustomerOrder) =>
    dispatch({
      type: 'UPDATE_ORDER',
      payload: {
        order,
      },
    });

  const updateOrderItem = (orderItem: CustomerOrderItem) =>
    dispatch({
      type: 'UPDATE_ORDER_ITEM',
      payload: {
        orderItem,
      },
    });

  const updateShipment = (shipment: QuickCircuitShipment) =>
    dispatch({
      type: 'UPDATE_SHIPMENT',
      payload: {
        shipment,
      },
    });

  return (
    <CustomerOrderContext.Provider
      value={{
        ...state,
        addShipment,
        addDevice,
        addOrderItem,
        removeDevice,
        removeOrderItem,
        removeShipment,
        updateCustomer,
        updateOrder,
        updateOrderItem,
        updateShipment,
      }}
    >
      {children}
    </CustomerOrderContext.Provider>
  );
};
