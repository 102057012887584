import type { FC } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import TetherButton from './TetherButton';

interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  subtitle?: string;
  onConfirm: (Event) => any;
  onClose: (Event) => any;
}

const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  const { open, onConfirm, onClose, title, subtitle, ...other } = props;
  return (
    <Dialog maxWidth="sm" onClose={onClose} onBackdropClick={onClose} open={open} {...other}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography color="textPrimary" variant="h5">
            {title}
          </Typography>
          <Typography color="textSecondary" variant="h6" sx={{ mt: 1, mb: 3 }}>
            {subtitle}
          </Typography>
        </Box>
        <Box>
          <TetherButton color="primary" variant="outlined" onClick={onClose} sx={{ marginRight: '20px', minWidth: '140px' }}>
            Cancel
          </TetherButton>

          <TetherButton color="primary" variant="contained" onClick={onConfirm} sx={{ marginRight: '20px', minWidth: '140px' }}>
            Confirm
          </TetherButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;
