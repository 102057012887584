import TetherApi from 'src/lib/tether-microservices/TetherApi';
import { GenericDevice } from './GenericDevice';

export class QuickCircuitShipmentDevice {
  private device;

  quickCircuitShipmentId: string;

  deviceId: string;

  createAt: string;

  constructor(props: QuickCircuitShipmentDevice) {
    Object.keys(props).forEach((key) => (this[key] = props[key]));
  }

  async getDevice(): Promise<GenericDevice> {
    if (this.device) {
      return this.device;
    }

    this.device = await TetherApi.getDevice(this.deviceId);

    return this.device;
  }
}
