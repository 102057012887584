import { createContext, FC, ReactNode, useReducer } from 'react';

interface State {
  properties: object,
  editedProperties: object
  editing: boolean
}

interface EditablePropertyListContextValue extends State {
  setProperties: (properties: object) => void;
  edit: () => void;
  cancelEdit: () => void;
  save: (properties: object) => void;
  updateProperty: (propertyName: string, value: string | boolean | number) => void;
}

interface EditablePropertyListProviderProps {
  children: ReactNode;
}

const initialState: State = {
  properties: null,
  editedProperties: {},
  editing: false
};

type BeginEditAction = {
  type: 'BEGIN_EDIT';
};

type CancelEditAction = {
  type: 'CANCEL_EDIT';
};

type SetPropertiesAction = {
  type: 'SET_PROPERTIES',
  payload: {
    properties: object
  }
};

type SaveAction = {
  type: 'SAVE';
  payload: {
    properties: object
  }
};

type UpdateOrderAction = {
  type: 'UPDATE_ORDER';
  payload: {
    propertyName: string;
    value: string;
  };
};

type UpdatePropertyAction = {
  type: 'UPDATE_PROPERTY';
  payload: {
    propertyName: string;
    value: string;
  };
};

type Action =
  BeginEditAction
  | CancelEditAction
  | SaveAction
  | SetPropertiesAction
  | UpdateOrderAction
  | UpdatePropertyAction;

const handlers: Record<string, (state: State, action: Action) => State> = {
  BEGIN_EDIT: (state: State): State => ({
    ...state,
    editing: true,
    editedProperties: {},
  }),
  CANCEL_EDIT: (state: State): State => ({
    ...state,
    editing: false,
    editedProperties: {}
  }),
  SAVE: (state: State, action: SetPropertiesAction): State => {
    const { properties } = action.payload;

    return {
      ...state,
      editing: false,
      properties,
      editedProperties: {}
    };
  },
  SET_PROPERTIES: (state: State, action: SetPropertiesAction): State => {
    const { properties } = action.payload;

    return {
      ...state,
      properties
    };
  },
  UPDATE_PROPERTY: (state: State, action: UpdatePropertyAction): State => {
    const { editedProperties } = state;
    editedProperties[action.payload.propertyName] = action.payload.value;

    return {
      ...state,
      editedProperties
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export const EditablePropertyListContext = createContext<EditablePropertyListContextValue>({
  ...initialState,
  cancelEdit: () => {},
  edit: () => {},
  save: () => {},
  setProperties: () => {},
  updateProperty: () => {}
});

export const EditablePropertyListProvider: FC<EditablePropertyListProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const cancelEdit = () => (
    dispatch({
      type: 'CANCEL_EDIT',
    })
  );

  const edit = () => (
    dispatch({
      type: 'BEGIN_EDIT',
    })
  );

  const save = (properties: object) => (
    dispatch({
      type: 'SAVE',
      payload: {
        properties
      }
    })
  );

  const setProperties = (properties: object) => {
    dispatch({
      type: 'SET_PROPERTIES',
      payload: {
        properties
      }
    });
  };

  const updateProperty = (propertyName: string, value: string) => (
    dispatch({
      type: 'UPDATE_PROPERTY',
      payload: {
        propertyName,
        value
      }
    })
  );

  return (
    <EditablePropertyListContext.Provider
      value={{
        ...state,
        cancelEdit,
        edit,
        save,
        setProperties,
        updateProperty
      }}
    >
      {children}
    </EditablePropertyListContext.Provider>
  );
};
