import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, keyframes } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import Logo from './Logo';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0.8;
  }
`;

// Utilize the sx prop for dynamic styles instead of makeStyles
const animatedItemSx = {
  animation: `${spin} 1500ms infinite linear`,
};

interface LoadingScreenProps {
  sx?: SxProps<Theme>;
}

const LoadingScreen = (props: LoadingScreenProps) => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%" }} display="flex">
      <Box
      sx={{
        minHeight: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flex: 1,
        ...props.sx,
      }}
    >
      <Box sx={animatedItemSx}>
        <Logo {...props} />
      </Box>
    </Box>
    </Box>
  );
};

export default LoadingScreen;
