export const capitaliseFirstCharacter = (input: string, restOfStringToLowercase: boolean = true): string => {
  if (!input || input.length === 0) {
    return '';
  }

  let rest = input.substring(1);
  if (restOfStringToLowercase) {
    rest = rest.toLowerCase();
  }
  return `${input[0].toUpperCase()}${rest}`;
};

export const camelCaseToUserText = (input: string): string => (
  `${input[0].toUpperCase()}${input.replace(/([A-Z](?=[a-z]+)|[A-Z]+(?![a-z]))/g, ' $1').slice(1)}`
);

export const snakeCaseToUserText = (input: string, capitaliseAllWords?: boolean): string => (
  `${input[0].toUpperCase()}${input.toLowerCase().replace(/(_[a-z])/g, (value) => (capitaliseAllWords ? ` ${value[1].toUpperCase()}` : ` ${value[1]}`)).slice(1)}`
);
