import React, { useContext, useState, useEffect } from 'react';
import { Box, Card, CardHeader, Divider, FormHelperText, Table, TableBody } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@emotion/react';
import CheckIcon from 'src/icons/Check';
import CrossIcon from 'src/icons/X';
import PencilAltIcon from 'src/icons/PencilAlt';
import { EditablePropertyListContext, EditablePropertyListProvider } from './EditablePropertyListContext';
import TetherButton from '../TetherButton';

interface EditablePropertyListProps {
  editable?: boolean;
  title?: string;
  sx?: SxProps<Theme>;
  displayObject: object;
  onSave?: (object) => Promise<object>;
  headerButtons?: React.ReactNode;
  children: React.ReactNode;
}

const EditablePropertyListInner = (props: EditablePropertyListProps) => {
  const { setProperties, edit, cancelEdit, editing, editedProperties, save } = useContext(EditablePropertyListContext);
  const { editable, title, sx, children, displayObject, onSave, headerButtons, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  // useEffect(() => {
  //   if (initiallyEditing) {
  //     edit();
  //   }
  // }, [initiallyEditing]);

  useEffect(() => {
    setProperties(displayObject);
    // we want to add setProperties as a dependency as it creates an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayObject]);

  const handleCancel = () => {
    setErrors({});
    cancelEdit();
  };

  const handleSave = async () => {
    setLoading(true);
    setErrors({});
    try {
      if (onSave) {
        const result = await onSave(editedProperties);
        save(result);
      } else {
        save(editedProperties);
      }
      setErrors({});
    } catch (e) {
      setErrors({ submit: e.message });
    } finally {
      setLoading(false);
    }
  };

  const showHeaderButtons = () => {
    if (!editable) {
      return Boolean(headerButtons) ? (
        <Box>
          {headerButtons}
        </Box>
      ) : null;
    }

    if (editing) {
      return (
        <Box>
          {headerButtons}
          <TetherButton
            startIcon={<CrossIcon fontSize="small" />}
            sx={{ m: 1, width: '100px' }}
            variant="outlined"
            disabled={loading}
            onClick={() => handleCancel()}
          >
            Cancel
          </TetherButton>
          <TetherButton
            color="primary"
            startIcon={<CheckIcon fontSize="small" />}
            sx={{ m: 1, width: '100px' }}
            variant="contained"
            onClick={() => handleSave()}
            loading={loading}
            disabled={Object.keys(editedProperties).length === 0}
          >
            Save
          </TetherButton>
        </Box>
      );
    }

    return (
      <Box>
        {headerButtons}
        <TetherButton
          color="primary"
          startIcon={<PencilAltIcon fontSize="small" />}
          sx={{ m: 1, width: '100px' }}
          variant="contained"
          onClick={() => edit()}
        >
          Edit
        </TetherButton>
      </Box>
    );
  };

  return (
    <Card {...other} sx={sx}>
      {(editable || !!title) && (
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            m: -1,
            mb: 0,
            p: 1,
            pb: 0.5,
            position: 'relative',
          }}
        >
          <CardHeader title={title} />
          {showHeaderButtons()}
          {errors.submit && (
            <Box
              sx={{
                mt: 0,
                pr: 2,
                right: 4,
                bottom: 0,
                display: 'block',
                position: 'absolute',
              }}
            >
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
        </Box>
      )}
      <Divider />
      <Box>
        <Table>
          <TableBody>{children}</TableBody>
        </Table>
      </Box>
    </Card>
  );
};

const EditablePropertyList = (props: EditablePropertyListProps) => (
  <EditablePropertyListProvider>
    <EditablePropertyListInner {...props} />
  </EditablePropertyListProvider>
);

export default EditablePropertyList;
