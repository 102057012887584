import { XeroTenant, XeroTenantStatus } from 'src/types/XeroTenant';
import { UseQueryOptions, UseQueryResult, useQuery, useQueryClient, useMutation } from 'react-query';
import { XeroBrandingTheme } from 'src/types/XeroBrandingTheme';
import { QueryParams, get, put } from '../utils';
import { AxiosError } from 'axios';

enum QueryKeys {
  XeroTenant = 'XeroTenant',
  XeroBrandingThemes = 'XeroBrandingThemes',
  XeroTenantStatus = 'XeroTenantStatus',
}

interface XeroTenantQuery extends QueryParams {
  name?: string;
}
export async function searchXeroTenants(query?: XeroTenantQuery): Promise<XeroTenant[]> {
  return get<XeroTenant[]>(`xerotenant`, query);
}

export function useSearchXeroTenants(
  query?: XeroTenantQuery,
  options?: UseQueryOptions<XeroTenant[], Error>
): UseQueryResult<XeroTenant[], Error> {
  return useQuery([QueryKeys.XeroTenant, query], () => searchXeroTenants(query), options);
}

export async function getXeroTenant(id: string): Promise<XeroTenant> {
  return get<XeroTenant>(`xerotenant/${id}`);
}

export function useGetXeroTenant(id: string, options?: UseQueryOptions<XeroTenant, Error>): UseQueryResult<XeroTenant, Error> {
  return useQuery([QueryKeys.XeroTenant, id], () => getXeroTenant(id), { enabled: !!id, ...options });
}

export async function getXeroBrandingThemes(xeroTenantId: string) {
  return get(`xerotenant/${xeroTenantId}/brandingthemes`);
}

export function useGetXeroBrandingThemes(xeroTenantId: string, options?: UseQueryOptions<XeroBrandingTheme[], Error>): UseQueryResult<XeroBrandingTheme[], Error> {
  return useQuery([QueryKeys.XeroBrandingThemes, xeroTenantId], () => getXeroBrandingThemes(xeroTenantId), { enabled: !!xeroTenantId, ...options });
}

export function useGetXeroTenantStatus(xeroTenantId: string, options?: UseQueryOptions<XeroTenantStatus, AxiosError>): UseQueryResult<XeroTenantStatus, AxiosError> {
  return useQuery([QueryKeys.XeroTenantStatus, xeroTenantId], () => get(`xerotenant/${xeroTenantId}/status`), { enabled: !!xeroTenantId, ...options });
}

export function useSetXeroTenantClientCredentialsMutation() {
  const queryClient = useQueryClient();

  const setXeroTenantClientCredentials = async (input: { xeroTenantId: string, clientId: string, clientSecret: string }) => {
    const { xeroTenantId, clientId, clientSecret } = input;
    return put(`xerotenant/${xeroTenantId}/client-credentials`, { clientId, clientSecret });
  }

  return useMutation(setXeroTenantClientCredentials, {
    onSuccess: () => {
      queryClient.resetQueries(QueryKeys.XeroTenant);
      queryClient.resetQueries(QueryKeys.XeroTenantStatus);
    },
  });
}

interface AuthorizeProps { xeroTenantId: string, authorizationCode: string };

export function useAuthorizeXeroTenantMutation() {
  const queryClient = useQueryClient();

  const authorizeXeroTenant = async (input: AuthorizeProps) => {
    const { xeroTenantId, authorizationCode } = input;
    await put(`xerotenant/${xeroTenantId}/authorize`, { authorizationCode });
  }

  return useMutation<void, AxiosError, AuthorizeProps>(authorizeXeroTenant, {
    onSuccess: () => {
      queryClient.resetQueries(QueryKeys.XeroTenant);
      queryClient.resetQueries(QueryKeys.XeroTenantStatus);
    },
  });
}
