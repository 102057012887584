import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Customer } from 'src/types/Customer';
import { CustomerMergeLog } from 'src/types/CustomerMergeLog';
import { UserCustomer } from 'src/types/UserCustomer';
import { useAggregatedQueries } from 'src/hooks/useAggregatedQuery';
import { get, post, put } from '../utils';

export function createCustomer(name: string, tetherDescription?: string, isVerified?: boolean): Promise<Customer> {
  const body = {
    name,
    tetherDescription,
    isVerified,
  };
  return post<Customer>('customer', body);
}

export function updateCustomer(customer: Customer) {
  return put<Customer>(`customer/${customer.id}`, { ...customer });
}

export function getCustomerById(customerId: string): Promise<Customer> {
  return get<Customer>(`customer/${customerId}`, { includeDeleted: true });
}

export function getCustomerAdmins(customerId: string): Promise<UserCustomer[]> {
  return get<UserCustomer>(`usercustomer`, { customerId });
}

type NewCustomerAdmin = {
  userId: string;
  customerId: string;
  roleId: 'admin';
};

export function createCustomerAdmin(customerAdmin: NewCustomerAdmin): Promise<UserCustomer> {
  return post<UserCustomer>('usercustomer', customerAdmin);
}

export function useQueryCustomerById(customerId: string, options: UseQueryOptions<Customer, Error> = {}): UseQueryResult<Customer, Error> {
  return useQuery(['Customer', customerId], () => getCustomerById(customerId), options);
}

export function useQueryCustomersById(customerIds: string[], options?: UseQueryOptions<Customer>) {
  return useAggregatedQueries<Customer>(
    customerIds.map((customerId) => ({
      queryKey: ['Customer', customerId],
      queryFn: () => getCustomerById(customerId),
      ...options,
    }))
  );
}

export function searchCustomers(name: string, includeDeleted: boolean = false): Promise<Customer[]> {
  return get<Customer[]>('customer', { name, includeDeleted });
}

export function useQueryCustomers(
  name: string,
  includeDeleted: boolean = false,
  options?: UseQueryOptions<Customer[]>
): UseQueryResult<Customer[]> {
  return useQuery(['CustomerSearch', name, includeDeleted], () => searchCustomers(name, includeDeleted), options);
}

export function mergeCustomers(sourceCustomerId: string, destinationCustomerId: string): Promise<void> {
  return post('customermerge', {}, { sourceCustomerId, destinationCustomerId });
}

export function getCustomerMergeLog(customerId: string): Promise<CustomerMergeLog[]> {
  return get<CustomerMergeLog[]>(`customer/${customerId}/mergelog`);
}

export function useQueryCustomerMergeLog(customerId: string): UseQueryResult<CustomerMergeLog[]> {
  return useQuery(['CustomerMergeLog', customerId], () => getCustomerMergeLog(customerId));
}
