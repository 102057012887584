import { QueryKey, useQueries, UseQueryOptions } from 'react-query';

export type AggregatedQueriesResponse<TData, TError> = {
  data: TData[],
  errors: TError[],
  isError: boolean,
  isLoading: boolean,
}

// An extension for useQueries that gives aggregated values for isLoading, isFetching etc
export function useAggregatedQueries<TQueryFnData = unknown, TError = Error, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(queries: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>[]): AggregatedQueriesResponse<TData, TError> {
  const useQueriesResult = useQueries(queries);

  const isError = !!useQueriesResult.find((r) => r.isError);
  const isLoading = !!useQueriesResult.find((r) => r.isLoading);

  return {
    data: useQueriesResult.map((r) => r.data as TData),
    errors: useQueriesResult.map((r) => r.error as TError).filter((e) => !!e),
    isError,
    isLoading,
  };
}
