import { Box, SelectProps, Typography } from '@mui/material';
import { CurrencyDescription } from 'src/types/CurrencyCode';
import TetherApi from 'src/lib/tether-microservices/TetherApi';
import LoadingScreen from '../LoadingScreen';

interface PriceSetDisplayProps extends Omit<SelectProps, 'onChange' | 'value'> {
  priceSetId: string;
}

export default function PriceSetDisplay({ priceSetId }: PriceSetDisplayProps) {
  const { data: priceSets = [], isLoading, error } = TetherApi.useSearchPriceSets();

  const priceSet = priceSets?.find((ps) => ps.id === priceSetId);

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && error && <div>Error</div>}
      {!isLoading && !error && (
        <Box>
          {!priceSet && <Typography>Not Set (using default)</Typography>}
          {!!priceSet && (
            <>
              <Typography color="textPrimary" variant="body2">
                {priceSet.name}
              </Typography>
              <Typography color="textSecondary" variant="caption">
                {priceSet.description}
              </Typography>
              <Typography color="textSecondary" variant="caption">
                {' '}
                - {CurrencyDescription[priceSet.currency]}
              </Typography>
            </>
          )}
        </Box>
      )}
    </>
  );
}
