import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden } from '@mui/material';
import { BsCurrencyEuro } from 'react-icons/bs';
import useAuth from 'src/hooks/useAuth';
import CurrencyDollar from 'src/icons/CurrencyDollar';
import XIcon from '../../icons/X';
import BriefcaseIcon from '../../icons/Briefcase';
import CashIcon from '../../icons/Cash';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import CollectionIcon from '../../icons/Collection';
import ClipboardListIcon from '../../icons/ClipboardList';
import HomeIcon from '../../icons/Home';
import LockIcon from '../../icons/Lock';
import ShareIcon from '../../icons/Share';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import ChatAltIcon from '../../icons/ChatAlt';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import Cog from 'src/icons/Cog';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Overview',
        path: '/',
        permissions: ['SEARCH_CUSTOMER'],
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Customers',
        path: '/customers',
        permissions: ['SEARCH_CUSTOMER'],
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'Organisations',
        path: '/organisations',
        permissions: ['SEARCH_ORGANISATION'],
        icon: <ShareIcon fontSize="small" />,
      },
      {
        title: 'Properties',
        path: '/properties',
        permissions: ['SEARCH_INSTALLATION'],
        icon: <HomeIcon fontSize="small" />,
      },
      {
        title: 'Devices',
        icon: <BriefcaseIcon fontSize="small" />,
        path: '/devices',
        permissions: ['SEARCH_DEVICE'],
      },
      {
        title: 'Users',
        path: '/users',
        permissions: ['SEARCH_USER'],
        icon: <UserIcon fontSize="small" />,
      },
      {
        title: 'Inventory',
        icon: <BriefcaseIcon fontSize="small" />,
        path: '/inventory',
        permissions: ['SEARCH_DEVICE_INVENTORY_LOG'],
      },

      {
        title: 'Orders',
        path: '/orders',
        permissions: ['SEARCH_CUSTOMER_ORDER'],
        icon: <ClipboardListIcon fontSize="small" />,
      },
      {
        title: 'Invoice Search',
        path: '/invoicesearch',
        permissions: ['SEARCH_CUSTOMER_INVOICE'],
        icon: <ClipboardListIcon fontSize="small" />,
      },
      {
        title: 'Pricing',
        icon: <BsCurrencyEuro fontSize="small" />,
        path: '/pricesets',
        permissions: ['SEARCH_PRICE_SET'],
      },
    ],
  },
  {
    title: 'Tools',
    items: [
      {
        title: 'System Reports',
        path: '/tools/reports',
        permissions: ['SEARCH_REPORT'],
        icon: <ClipboardListIcon fontSize="small" />,
      },
      {
        title: 'Device Contracts',
        path: '/tools/device-contracts',
        permissions: ['SEARCH_DEVICE_CONTRACT'],
        icon: <CashIcon fontSize="small" />,
      },
      {
        title: 'Device Operations',
        path: '/tools/device-operations',
        permissions: ['CREATE_DEVICE'],
        icon: <BriefcaseIcon fontSize="small" />,
      },
      {
        title: 'Invoicing',
        path: '/tools/invoicing',
        permissions: ['SEARCH_CUSTOMER_INVOICE'],
        icon: <CurrencyDollar fontSize="small" />,
      },
      {
        title: 'Xero Settings',
        path: '/tools/xero-settings',
        permissions: ['UPDATE_XERO_TENANT'],
        icon: <Cog fontSize="small" />,
      }
    ],
  },
  {
    title: 'QuickCircuit',
    items: [
      {
        title: 'Orders',
        path: '/quickcircuit/orders',
        permissions: ['SEARCH_CUSTOMER_ORDER'],
        icon: <ClipboardListIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Feedback',
    items: [
      {
        title: 'Insight Highlight Votes',
        path: '/feedback/insight-highlight-votes',
        permissions: ['SEARCH_INSIGHTHIGHLIGHTVOTES'],
        icon: <ChatAltIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'User',
    items: [
      {
        title: 'Manage Tether Users',
        path: 'accesskeys',
        permissions: ['SEARCH_ACCESS_KEY'],
        icon: <LockIcon fontSize="small" />,
      },
      {
        title: 'Manage Tether Roles',
        path: 'accessroles',
        permissions: ['SEARCH_ACCESS_KEY'],
        icon: <CollectionIcon fontSize="small" />,
      },
      {
        title: 'Sign Out',
        path: 'authentication/logout',
        permissions: [],
        icon: <XIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { permissions } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => {
            const visibleItems = section.items.filter(
              (item) => !item.permissions.map((p) => !permissions.includes(p)).reduce((a, b) => a || b, false)
            );

            if (visibleItems.length === 0) {
              return null;
            }
            return (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3,
                  },
                }}
                {...section}
                items={visibleItems}
              />
            );
          })}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
