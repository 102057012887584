import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import useAuth from '../../../hooks/useAuth';

const LoginCookie: FC = (props) => {
  const { login } = useAuth() as any;

  return (
    <Formik
      initialValues={{
        key: '',
        secret: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        key: Yup.string().max(255).required('Username is required'),
        secret: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
        try {
          await login(values.key, values.secret);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          console.error(err);

          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.key && errors.key)}
            fullWidth
            helperText={touched.key && errors.key}
            label="Username"
            margin="normal"
            name="key"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.key}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.secret && errors.secret)}
            fullWidth
            helperText={touched.secret && errors.secret}
            label="Password"
            margin="normal"
            name="secret"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.secret}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit?.toString()}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginCookie;
