import { useQuery, UseQueryResult } from 'react-query';
import { Organisation } from 'src/types/Organisation';
import { get, post } from '../utils';

export function getOrganisationById(organisationId: string): Promise<Organisation> {
  return get<Organisation>(`organisation/${organisationId}`);
}

export function getOrganisationsForCustomer(customerId: string): Promise<Organisation[]> {
  return get<Organisation[]>('organisation', { customerId });
}

export function searchOrganisations(name: string): Promise<Organisation[]> {
  return get<Organisation[]>('organisation', { name });
}

export function createOrganisation(name: string, customerId: string): Promise<Organisation> {
  const body = {
    name,
    customerId,
  };
  return post<Organisation>('organisation', body);
}

export function useQueryOrganisationsForCustomer(customerId: string): UseQueryResult<Organisation[]> {
  return useQuery(['Organisations', customerId], () => getOrganisationsForCustomer(customerId));
}
