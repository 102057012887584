import { FC, useState } from 'react';
import TetherTable from 'src/components/table/TetherTable';
import Page from './Page';
import { useSearchXeroTenants } from 'src/lib/tether-microservices/routes/xero';
import { XeroTenant } from 'src/types/XeroTenant';
import XeroTenantAccessKeyModal from 'src/components/xero/XeroTenantAccessKeyModal';

const XeroSettingsPage: FC = () => {
  const { data: xeroTenants = [], isLoading: tenantsLoading, error: tenantsError } = useSearchXeroTenants();
  const [openTenant, setOpenTenant] = useState<XeroTenant | null>(null);

  return (
    <Page helmetTitle="Xero Settings | Tether" isLoading={tenantsLoading} hasError={!!tenantsError} errorString={tenantsError?.message}>
      <TetherTable<XeroTenant>
        data={xeroTenants}
        rowRenderKeyAttribute="id"
        columns={[
          {
            attribute: 'name',
          },
          {
            attribute: 'description',
          },
        ]}
        onRowClick={(tenant => setOpenTenant(tenant))}

      />
      {!!openTenant && <XeroTenantAccessKeyModal xeroTenant={openTenant} isOpen onClose={() => setOpenTenant(null)} />}
    </Page>
  );
};

export default XeroSettingsPage;
