import { New } from 'src/lib/dbHelper';
import { PriceSet } from 'src/types/PriceSet';
import { UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryParams, get, post, put } from '../utils';
import { useQueryCustomerById } from './customer';
import { useSearchPriceSetItemTypes } from './price-set-item-type';
import { useGetPriceSetItems } from './price-set-item';

enum QueryKeys {
  'PriceSet' = 'PriceSet',
}

export async function getPriceSet(id: string): Promise<PriceSet> {
  return get<PriceSet>(`priceset/${id}`);
}

export async function createPriceSet(priceSet: New<PriceSet>): Promise<PriceSet> {
  return post<PriceSet>('priceset', priceSet);
}

export async function updatePriceSet(priceSet: PriceSet): Promise<PriceSet> {
  return put<PriceSet>(`priceset/${priceSet.id}`, { ...priceSet });
}

export interface PriceSetQuery extends QueryParams {
  name?: string;
  isDefault?: boolean;
}

export async function searchPriceSets(query?: PriceSetQuery): Promise<PriceSet[]> {
  return get<PriceSet[]>('priceset', query || {});
}

export function useGetPriceSet(id: string, options?: UseQueryOptions<PriceSet, Error>): UseQueryResult<PriceSet, Error> {
  return useQuery([QueryKeys.PriceSet, id], () => getPriceSet(id), options);
}

export function useSearchPriceSets(query?: PriceSetQuery, options?: UseQueryOptions<PriceSet[], Error>): UseQueryResult<PriceSet[], Error> {
  return useQuery([QueryKeys.PriceSet, query], () => searchPriceSets(query), options);
}

export function useCreatePriceSetMutation() {
  const queryClient = useQueryClient();

  return useMutation((priceSet: New<PriceSet>) => createPriceSet(priceSet), {
    onSuccess: () => {
      queryClient.resetQueries(QueryKeys.PriceSet);
    },
  });
}

export function useUpdatePriceSetMutation() {
  const queryClient = useQueryClient();

  return useMutation((priceSet: PriceSet) => updatePriceSet(priceSet), {
    onSuccess: () => {
      queryClient.resetQueries(QueryKeys.PriceSet);
    },
  });
}

export function useGetPriceSetOrDefaultForCustomer(customerId: string) {
  const { data: customer, isLoading: customerLoading, error: customerError } = useQueryCustomerById(customerId, { enabled: !!customerId });
  const {
    data: priceSet,
    isLoading: priceSetLoading,
    error: priceSetError,
  } = useGetPriceSet(customer?.priceSetId, { enabled: !!customer?.priceSetId });
  const {
    data: [priceSetDefault] = [],
    isLoading: priceSetDefaultLoading,
    error: priceSetDefaultError,
  } = useSearchPriceSets({ isDefault: true }, { enabled: customer && !customer.priceSetId });

  const isLoading = customerLoading || priceSetLoading || priceSetDefaultLoading;
  const error = customerError || priceSetError || priceSetDefaultError;

  return {
    data: priceSet || priceSetDefault,
    isLoading,
    error,
  };
}

export function useGetPricingForCustomer(customerId: string) {
  const { data: priceSet, isLoading: priceSetLoading, error: priceSetError } = useGetPriceSetOrDefaultForCustomer(customerId);
  const { data: priceSetItems, isLoading: priceSetItemsLoading, error: priceSetItemsError } = useGetPriceSetItems(priceSet?.id, {
    enabled: !!priceSet,
  });
  const { data: priceSetItemTypes, isLoading: priceSetItemTypesLoading, error: priceSetItemTypesError } = useSearchPriceSetItemTypes();

  return {
    priceSet,
    priceSetItems,
    priceSetItemTypes,
    isLoading: priceSetLoading || priceSetItemsLoading || priceSetItemTypesLoading,
    error: priceSetError || priceSetItemsError || priceSetItemTypesError,
  };
}