import { useEffect, useState, type FC } from 'react';
import { Box, Card, CardHeader, Divider, Stack, Typography } from '@mui/material';
import Scrollbar from '../Scrollbar';
import LoadingScreen from '../LoadingScreen';
import { CombinedRoomRadarSamplesQuery, getRoomRadarCombinedSamples, searchRoomLocations } from 'src/lib/tether-microservices/routes/property';
import useLog from 'src/hooks/useLog';
import { Room } from 'src/types/Room';
import TetherButton from '../TetherButton';
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { useGetProperty, useGetPropertyInfo } from 'src/lib/tether-microservices/routes/device';

import { toZonedTime } from 'date-fns-tz';
import { subDays, format } from 'date-fns';



interface Props {
  room: Room;
}

const RoomDebug: FC<Props> = ({ room }) => {
  const log = useLog();

  const [roomLocations, setRoomLocations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState<Date>(subDays(new Date(), 7));
  const [endTime, setEndTime] = useState<Date>(new Date());

  const [resetTime, setResetTime] = useState<Date>(new Date("1970-01-01 3:00"));

  const { propertyInfo } = useGetPropertyInfo(room.propertyId);
  const { property } = useGetProperty(room.propertyId);

  useEffect(() => {
    if (!room?.id) {
      return;
    }
    
    searchRoomLocations({ roomId: room.id })
      .then(setRoomLocations)
      .catch(err => log.error(err.message));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  async function getCombinedOccupancySamples({ fromTimestamp, toTimestamp, occupancyResetTime }) {

    const propertyRoom = propertyInfo.rooms.find(r => r.id === room.id);
    const roomRadarParents = propertyRoom.roomLocations
      .flatMap(rl => {
        if (rl.device.type === 'ROOM_RADAR') {
          return rl.device;
        }
        return null;
      })
      .filter(Boolean);

    const roomRadarLineIds = roomRadarParents.map(parent => {
      const orderedSubDevices = parent.subDevices.filter(d => d.type === "ROOM_RADAR_LINE").sort((a, b) => a.subDeviceIndex - b.subDeviceIndex);
      if (orderedSubDevices.length === 0) {
        return null;
      }
      return orderedSubDevices[0].id;
    });


    const query: CombinedRoomRadarSamplesQuery = {
      // todo from import controls
      fromTimestamp,
      toTimestamp,
      deviceIds: roomRadarLineIds.join(','),
      timezone: property.timezone || 'UTC',
      occupancyResetTime,
      withDeviceSamples: 'true',
      filterMetrics: 'periodIn,periodOut,totalIn,totalOut,occupancy,occupancyDelta,rawOccupancy'
    };

    const combinedSamples = await getRoomRadarCombinedSamples(query);

    const seenDeviceIds = [];
    const rows = [];

    for (const combinedSample of combinedSamples) {
      if (!combinedSample.deviceId || !combinedSample.sample || (combinedSample.sample.occupancy ?? -1) < 0 || (combinedSample.sample.occupancyDelta ?? -1) < 0) {
        continue;
      }

      const row = [];
      if (!seenDeviceIds.includes(combinedSample.deviceId)) {
        seenDeviceIds.push(combinedSample.deviceId);
      }

      row.push(combinedSample.timestamp);
      row.push(format(toZonedTime(combinedSample.timestamp, property.timezone || 'UTC'), 'yyyy-MM-dd HH:mm:ss (xxx)'));
      row.push(combinedSample.isOccupancyReset ? 'RESET' : '');
      for (let i = 0; i < seenDeviceIds.length; i++) {
        if (!combinedSample.deviceSamples) {
          continue;
        }

        const deviceSample = combinedSample.deviceSamples[seenDeviceIds[i]];
          if (deviceSample) {
            row.push(deviceSample.totalIn);
            row.push(deviceSample.totalOut);
            row.push(deviceSample.periodIn);
            row.push(deviceSample.periodOut);
            row.push(deviceSample.rawOccupancy);
          } else {
            row.push('');
            row.push('');
            row.push('');
            row.push('');
            row.push('');
          }
      }

      row.push(combinedSample.sample.totalIn);
      row.push(combinedSample.sample.totalOut);
      row.push(combinedSample.sample.periodIn);
      row.push(combinedSample.sample.periodOut);
      row.push(combinedSample.sample.rawOccupancy);
      row.push(combinedSample.sample.occupancy);
      row.push(combinedSample.sample.occupancyDelta);

      rows.push(row);
    }

    const headers = [
        'timestampUTC',
        'timestampPropertyTimeZone',
        'isOccupancyReset',
        ...seenDeviceIds.map(deviceId => {
            return [
                `totalIn_${deviceId}`,
                `totalOut_${deviceId}`,
                `periodIn_${deviceId}`,
                `periodOut_${deviceId}`,
                `rawOccupancy_${deviceId}`,
            ]
        }),
        'totalIn',
        'totalOut',
        'periodIn',
        'periodOut',
        'rawOccupancy',
        'occupancy',
        'occupancyDelta',
    ].flat();

    const data = headers.join(',') + '\n' + rows.map(row => row.join(',')).join('\n');

    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;

    const fileName = [
      property.address1,
      room.name,
      'Combined-Occupancy-Samples'
    ]
    .map(x => x.replaceAll(',', '').replaceAll(' ', '-'))
    .join('_');
    a.download = `${fileName}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);

  }

  const buttonClicked = async () => {
    setIsLoading(true);
    try {
      await getCombinedOccupancySamples({
        fromTimestamp: startTime.toISOString(),
        toTimestamp: endTime.toISOString(),
        occupancyResetTime: format(resetTime, 'HH:mm:00'),
      });
    } catch (err) {
      log.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Card>
      <CardHeader title="Room Debug" />
      <Divider />
      <Scrollbar>
        <Stack padding={2} paddingY={4} direction='column' rowGap={5}>
          {roomLocations === null ? (
            <Box sx={{ height: 300 }}>
              <LoadingScreen sx={{ width: '80px' }} />
            </Box>
          ) : (
            <>
              <Stack direction='row' columnGap={2}>
                <DateTimePicker
                  label="Start"
                  value={startTime}
                  onChange={(value) => {
                    setStartTime(value);
                  }}
                />
                <DateTimePicker
                  label="End"
                  value={endTime}
                  onChange={(value) => {
                    setEndTime(value);
                  }}
                />
              </Stack>

              <Stack alignItems='flex-start' rowGap={1}>
                <TimePicker label="Reset Time" onChange={setResetTime} value={resetTime} />
                <Typography>
                  Timezone: {property?.timezone || 'UTC'}
                </Typography>
              </Stack>

              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <TetherButton
                  disabled={!propertyInfo || !property}
                  variant="outlined"
                  onClick={buttonClicked}
                  loading={isLoading}
                >
                  Get Combined Occupancy Samples
                </TetherButton>

                <Typography variant='body2'>Gets the combined samples which calculate occupancy across multiple devices. These are aggregated to the heatmap.</Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Scrollbar>
    </Card>
  );
};

export default RoomDebug;
