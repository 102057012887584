import { useQuery, UseQueryResult } from 'react-query';
import { useAggregatedQueries } from 'src/hooks/useAggregatedQuery';
import { AlarmHistory } from 'src/types/Alarms';
import { Organisation } from 'src/types/Organisation';
import { OrganisationInvite } from 'src/types/OrganisationInvite';
import { UserOrganisation } from 'src/types/UserOrganisation';
import { get, post } from '../utils';

export function getUserOrganisationsByOrganisationId(organisationId: string): Promise<UserOrganisation[]> {
  return get<UserOrganisation[]>('userorganisation', { organisationId });
}

export function getUserOrganisationsByUserId(userId: string): Promise<UserOrganisation[]> {
  return get<UserOrganisation[]>('userorganisation', { userId });
}

export function getUserAlertHistory(userId: string): Promise<AlarmHistory[]> {
  return get<UserOrganisation[]>('alerthistory', { userId, limit: 100 });
}

export function addUserToOrganisation(userId: string, organisationId: string, userRoleId: number): Promise<UserOrganisation> {
  const body = {
    userId,
    organisationId,
    userRoleId,
  };
  return post<UserOrganisation>('userorganisation', body);
}

export function inviteUserToOrganisation(email: string, organisationId: string, userRoleId: number): Promise<OrganisationInvite> {
  const body = {
    email,
    organisationId,
    userRoleId,
  };
  return post<OrganisationInvite>('userorganisation/invite', body);
}

export function useQueryUserOrganisationsByOrganisationId(organisationId: string): UseQueryResult<Organisation[]> {
  return useQuery(['UserOrganisation', organisationId], () => getUserOrganisationsByOrganisationId(organisationId));
}

export function useQueryAllUserOrganisationsByOrganisationIds(
  ids: string[],
  includeDeleted?: boolean,
  enabled?: boolean
) {
  return useAggregatedQueries<UserOrganisation[]>(
    enabled !== undefined && !enabled
      ? []
      : ids.map((id) => {
          const organisationId = id;
          return {
            queryKey: ['UserOrganisation', organisationId],
            queryFn: async () => {
              const device = await getUserOrganisationsByOrganisationId(organisationId);
              return device;
            },
          };
        })
  );
}

export function triggerSendAlarm(userId, item) {
  const body = {
    userId,
    type: 'AlarmHistory',
    item,
  };
  return post<OrganisationInvite>('userAlert', body);
}
