import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Alert, Box, Container } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import LoadingScreen from 'src/components/LoadingScreen';


interface PageProps {
  helmetTitle: string;
  children: React.ReactNode;
  isLoading?: boolean;
  hasError?: boolean;
  errorString?: string;
}

const Page: FC<PageProps> = ({ helmetTitle, children, isLoading = false, hasError = false, errorString = "" }) => {
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          {isLoading && <LoadingScreen />}
          {!isLoading && hasError && <Alert severity='error'>{errorString}</Alert>}
          {!isLoading && !hasError && children}
        </Container>
      </Box>
    </>
  );
};

export default Page;
