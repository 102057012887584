import { InsightHighlightVote } from 'src/types/InsightHighlightVote';
import { get, destroy } from '../utils';

export function getInsightHighlightVotes(): Promise<InsightHighlightVote[]> {
  return get<InsightHighlightVote[]>(`insighthighlightvotes`);
}

export function getInsightHighlightVote(propertyId: string, insightHighlightId: string): Promise<InsightHighlightVote> {
  return get<InsightHighlightVote>(`insighthighlightvotes/${propertyId}/${insightHighlightId}`);
}

export function deleteInsightHighlightVote(propertyId: string, insightHighlightId: string): Promise<{ message: string }> {
  return destroy(`insighthighlightvotes/${propertyId}/${insightHighlightId}`);
}
