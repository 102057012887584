import { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Divider, Grid, Link, Tab, Tabs, Typography } from '@mui/material';
import ChevronRightIcon from '../../icons/ChevronRight';

import useSettings from '../../hooks/useSettings';
import { useGetRoom } from 'src/lib/tether-microservices/routes/device';
import LoadingScreen from 'src/components/LoadingScreen';
import { EditableProperty, EditablePropertyList } from 'src/components/editablepropertylist';
import { format } from 'date-fns/format';
import RoomRoomLocations from 'src/components/room/RoomRoomLocations';
import RoomDebug from 'src/components/room/RoomDebug';

const tabs = [
  { label: 'Details', value: 'details' },
  { label: 'Room Locations', value: 'roomLocations' },
  { label: 'Debug', value: 'debug' },
];

const RoomDetailsPage: FC = () => {
  const { settings } = useSettings();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState<string>(location.hash ? location.hash.substr(1) : 'details');
  const { organisationId, installationId, roomId } = useParams();
  const navigate = useNavigate();

  const { room, isLoading } = useGetRoom(roomId);


  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    navigate(`${location.pathname}#${value}`, { replace: true });
    setCurrentTab(value);
  };

  if (isLoading) {
    return (
      <Box sx={{ height: 300 }}>
        <LoadingScreen sx={{ width: '80px' }} />
      </Box>
    )
  }


  return (
    <>
      <Helmet>
        <title>Room Details | Tether</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {`Room: ${room.name}`}
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                <Link color="textPrimary" component={RouterLink} to="/organisations" variant="subtitle2">
                  Organisations
                </Link>
                <Typography
                  color="textSecondary"
                  component={RouterLink}
                  to={`/organisations/${organisationId}#installationLocations`}
                  variant="subtitle2"
                >
                  Organisation Details
                </Typography>
                <Typography
                  color="textSecondary"
                  component={RouterLink}
                  to={`/organisations/${organisationId}/installations/${installationId}`}
                  variant="subtitle2"
                >
                  Property Details
                </Typography>
                <Typography color="textSecondary" variant="subtitle2">
                  Room Details
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'details' && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                <EditablePropertyList title="Room Details" displayObject={room}>
                    <EditableProperty fieldName="id" copyToClipboard />
                    <EditableProperty fieldName="propertyId" copyToClipboard />
                    <EditableProperty fieldName="name" />
                    <EditableProperty fieldName="type" />
                    <EditableProperty fieldName="color" customRender={(value) => <Typography variant="body2">{value}</Typography>} />
                    <EditableProperty fieldName="floorIndex" />
                    <EditableProperty fieldName="isMainLivingArea" />
                    <EditableProperty fieldName="customId" copyToClipboard />
                    <EditableProperty fieldName="capacity" />
                    <EditableProperty fieldName="createdAt" customRender={(value) => format(new Date(value), 'dd/MM/yyyy - HH:mm')} />
                    <EditableProperty fieldName="updatedAt" customRender={(value) => format(new Date(value), 'dd/MM/yyyy - HH:mm')} />
                    </EditablePropertyList>
                </Grid>
              </Grid>
            )}
            {currentTab === 'roomLocations' && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RoomRoomLocations room={room} />
                </Grid>
              </Grid>
            )}
            {currentTab === 'debug' && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RoomDebug room={room} />
                </Grid>
              </Grid>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default RoomDetailsPage;
