export enum CurrencyCode {
  AUD = 'AUD',
  BRL = 'BRL',
  GBP = 'GBP',
  CAD = 'CAD',
  DKK = 'DKK',
  EUR = 'EUR',
  HKD = 'HKD',
  INR = 'INR',
  IDR = 'IDR',
  ILS = 'ILS',
  JPY = 'JPY',
  MXN = 'MXN',
  NZD = 'NZD',
  NOK = 'NOK',
  PLN = 'PLN',
  RUB = 'RUB',
  SGD = 'SGD',
  ZAR = 'ZAR',
  KRW = 'KRW',
  SEK = 'SEK',
  CHF = 'CHF',
  TRY = 'TRY',
  USD = 'USD',
}

export const CurrencyDescription: Record<CurrencyCode, string> = {
  [CurrencyCode.AUD]: 'Australian Dollar (AUD $)',
  [CurrencyCode.BRL]: 'Brazilian Real (BRL R$)',
  [CurrencyCode.GBP]: 'British Pound Sterling (£)',
  [CurrencyCode.CAD]: 'Canadian Dollar (CAD $)',
  [CurrencyCode.DKK]: 'Danish Krone (DKK)',
  [CurrencyCode.EUR]: 'Euro (€)',
  [CurrencyCode.HKD]: 'Hong Kong Dollar (HKD $)',
  [CurrencyCode.INR]: 'Indian Rupee (INR ₹)',
  [CurrencyCode.IDR]: 'Indonesian Rupiah (IDR Rp)',
  [CurrencyCode.ILS]: 'Israeli Shekel (₪)',
  [CurrencyCode.JPY]: 'Japanese Yen (¥)',
  [CurrencyCode.MXN]: 'Mexican Peso (MXN $)',
  [CurrencyCode.NZD]: 'New Zealand Dollar (NZD $)',
  [CurrencyCode.NOK]: 'Norwegian Krone (NOK)',
  [CurrencyCode.PLN]: 'Polish Zloty (PLN zł)',
  [CurrencyCode.RUB]: 'Russian Ruble (RUB ₽)',
  [CurrencyCode.SGD]: 'Singapore Dollar (SGD $)',
  [CurrencyCode.ZAR]: 'South African Rand (ZAR R)',
  [CurrencyCode.KRW]: 'South Korean Won (KRW ₩)',
  [CurrencyCode.SEK]: 'Swedish Krona (SEK)',
  [CurrencyCode.CHF]: 'Swiss Franc (CHF)',
  [CurrencyCode.TRY]: 'Turkish Lira (TRY ₺)',
  [CurrencyCode.USD]: 'United States Dollar (USD $)',
};

export const CurrencySymbol: Record<CurrencyCode, string> = {
  [CurrencyCode.AUD]: '$',
  [CurrencyCode.BRL]: '$',
  [CurrencyCode.GBP]: '£',
  [CurrencyCode.CAD]: '$',
  [CurrencyCode.DKK]: 'DKK',
  [CurrencyCode.EUR]: '€',
  [CurrencyCode.HKD]: '$',
  [CurrencyCode.INR]: '₹',
  [CurrencyCode.IDR]: 'Rp',
  [CurrencyCode.ILS]: '₪',
  [CurrencyCode.JPY]: '¥',
  [CurrencyCode.MXN]: '$',
  [CurrencyCode.NZD]: '$',
  [CurrencyCode.NOK]: 'NOK',
  [CurrencyCode.PLN]: 'zł',
  [CurrencyCode.RUB]: '₽',
  [CurrencyCode.SGD]: '$',
  [CurrencyCode.ZAR]: 'R',
  [CurrencyCode.KRW]: '₩',
  [CurrencyCode.SEK]: 'SEK',
  [CurrencyCode.CHF]: 'CHF',
  [CurrencyCode.TRY]: '₺',
  [CurrencyCode.USD]: '$',
};
