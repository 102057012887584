import { FC, useEffect, useState } from 'react';
import Page from './Page';
import { useAuthorizeXeroTenantMutation } from 'src/lib/tether-microservices/routes/xero';
import { Alert, Box, Typography } from '@mui/material';
import LoadingScreen from 'src/components/LoadingScreen';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TetherButton from 'src/components/TetherButton';

const XeroAuthRedirectPage: FC = () => {
  const [params] = useSearchParams();
  const code = params.get("code");
  const state = params.get("state");
  const xeroError = params.get("error");

  const [mutateError, setMutateError] = useState<string | null>(null);
  const authorizeMutation = useAuthorizeXeroTenantMutation();
  const navigate = useNavigate();

  useEffect(() => {
    authorizeMutation.mutate(
      { authorizationCode: code, xeroTenantId: state },
      {
        onSuccess: () => { navigate('/tools/xero-settings', { replace: true }); },
        onError: (error) => { setMutateError(error.message); }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const error = xeroError || mutateError;

  return (
    <Page helmetTitle="Xero Connection | Tether">

      {error && (
        <Box>
          <Alert severity="error">
            Xero Authorisation Failed: {error}
          </Alert>
          <Box mt={5} />
          <TetherButton onClick={() => navigate('/tools/xero-settings', { replace: true })}>
            Return to Xero Settings
          </TetherButton>
        </Box>
      )}
      {!error && (
        <Box>
          <Typography variant="h3" gutterBottom>
            Validating Xero Connection
          </Typography>
          <LoadingScreen sx={{ width: '75px' }} />
        </Box>
      )}

    </Page>
  );
};

export default XeroAuthRedirectPage;
