/* eslint-disable class-methods-use-this */
import { GenericDevice } from './GenericDevice';

export class HotDrop implements GenericDevice {
  id: string;

  sku: string;

  serialNumber: string;

  devEUI: string;

  vutilitiId: string;

  hardwareVersion: string;

  networkType: string;

  backendVersion: string;

  createdAt: string;

  updatedAt: string;

  deletedAt: string;

  constructor(props: HotDrop) {
    Object.keys(props).forEach((key) => (this[key] = props[key]));
  }

  getDeviceType(): string {
    return 'HOTDROP';
  }

  getFriendlyDeviceType(): string {
    return 'HotDrop';
  }

  getPhysicalLabel(): string {
    return this.vutilitiId;
  }
}
