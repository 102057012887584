import { useContext } from 'react';
import { setTetherApiLogContext } from 'src/lib/tether-microservices/utils';
import LogContext from '../contexts/LogContext';

const useLog = () => {
  const context = useContext(LogContext);
  setTetherApiLogContext(context);
  return context;
};

export default useLog;
