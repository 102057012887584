import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { CustomerDeviceAssignment } from 'src/types/CustomerDeviceAssignment';
import { get, post } from '../utils';

enum QueryKeys {
  CUSTOMER_DEVICE_ASSIGNMENT = 'CustomerDeviceAssignment',
}

export function createCustomerDeviceAssignment(customerId: string, deviceId: string): Promise<CustomerDeviceAssignment> {
  const body = {
    customerId,
    deviceId,
  };
  return post<CustomerDeviceAssignment>('customerdeviceassignment', body);
}

export function getCustomerDeviceAssignments(): Promise<CustomerDeviceAssignment[]> {
  return get<CustomerDeviceAssignment[]>('customerdeviceassignment');
}

export function getCustomerDeviceAssignmentsForCustomer(customerId: string): Promise<CustomerDeviceAssignment[]> {
  return get<CustomerDeviceAssignment[]>('customerdeviceassignment', { customerId });
}

export function getCustomerDeviceAssignmentsForDevice(deviceId: string): Promise<CustomerDeviceAssignment[]> {
  return get<CustomerDeviceAssignment[]>('customerdeviceassignment', { deviceId });
}

export function useGetCustomerDeviceAssignmentsForCustomer(
  customerId: string,
  options?: UseQueryOptions<CustomerDeviceAssignment[], Error>
): UseQueryResult<CustomerDeviceAssignment[], Error> {
  return useQuery([QueryKeys.CUSTOMER_DEVICE_ASSIGNMENT, customerId], () => getCustomerDeviceAssignmentsForCustomer(customerId), options);
}
