import { camelCaseToUserText } from 'src/utils/stringUtils';

export enum DisplayType {
  DATE = 'date',
  DATETIME = 'datetime',
  CURRENCY = 'currency',
}
abstract class TetherTableColumn<T> {
  /** The name/key of the attribute to display in this column */
  attribute: keyof T;

  /** The text to display for the column header, will use the attribute name if not set */
  label?: string;

  /** Sets the DisplayType option that is used for automatically formatting and sorting certain types of values, e.g. dates */
  displayType?: DisplayType;

  /** Override the render for the data value */
  renderOverride?: (attributeValue: any, displayObject: T) => (string | JSX.Element);

  /** Gets the value of the attribute; can be overridden to allow for external values in sorting */
  getValue? = (displayObject: T) => displayObject[this.attribute];

  /** Can the column be sorted */
  sortable?: boolean;

  /** Override the default sort function (alphabetical */
  sort?: (a: T, b: T) => number;

  /** The name/key of the attribute to display as subtext */
  subTextAttribute?: keyof T;

  /** Gets the value of the attribute; can be overridden to allow for external values in sorting */
  getSubtextValue? = (displayObject: T) => (this.subTextAttribute ? displayObject[this.subTextAttribute] : null);

  /** If set this column will not be visible by default (note it can only be turned on if column selection is enabled on the table) */
  hideByDefault?: boolean;

  /** Will group adjacent columns with the same name together if groups are enabled on the table */
  groupName?: string;

  /** Disables word wrap for values displayed in this column */
  disableWrap?: boolean;

  /** Enables a button that will copy the value of this column to clipboard */
  copyToClipboard?: boolean;

  /** Column will be excluded from all table operations - used for conditionally visible column */
  disabled?: boolean;
}

export default TetherTableColumn;

export const getColumnLabel = (column: TetherTableColumn<any>) => (
  column.label || camelCaseToUserText(column.attribute as string)
);
