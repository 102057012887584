import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((
    error.response && error.response.data
  ) || error)
);

export const setBearerToken = (token: string): void => {
  axios.defaults.headers.common = {
    Authorization: token
  };
};

export default axiosInstance;
