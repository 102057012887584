import { FC } from 'react';
import { IconButtonProps, IconButton } from '@mui/material';
import { LogContextValue } from 'src/contexts/LogContext';
import Clipboard from 'src/icons/Clipboard';
import copyToClipboard from 'src/utils/copyToClipboard';

interface CopyToClipboardProps extends IconButtonProps {
  text: string;
  log?: LogContextValue;
}

const CopyToClipboardButton: FC<CopyToClipboardProps> = (props) => {
  const { text, log, ...other } = props;

  if (!text) return null;
  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        copyToClipboard(text, log);
      }}
      title="Copy to clipboard"
      {...other}
      size="large"
    >
      <Clipboard />
    </IconButton>
  );
};

export default CopyToClipboardButton;
