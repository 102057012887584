import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import InsufficientPermisionPage from 'src/pages/InsufficientPermissionPage';
import useAuth from '../hooks/useAuth';

interface PermissionGuardProps {
  required: string | string[];
  children: ReactNode;
  fallbackRoute?: string;
}

const PermissionGuard: FC<PermissionGuardProps> = (props) => {
  const { required, children, fallbackRoute } = props;
  const permissions = typeof (required) === 'string' ? [required] : required;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const forbidden = permissions.map((p) => (!auth.permissions.includes(p))).reduce((a, b) => a || b);
  if (forbidden) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    if (fallbackRoute) {
      return <Navigate to={fallbackRoute} />;
    }

    return <InsufficientPermisionPage />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

PermissionGuard.propTypes = {
  children: PropTypes.node
};

export default PermissionGuard;
