import { Gateway } from 'src/types/Gateway';
import { get, post } from '../utils';

export async function getGateway(deviceId: string) {
  return get<Gateway>(`gateway/${deviceId}`);
}

export type GatewayStatus = {
  lastUplinkReceivedAt?: string;
  isConnected?: boolean;
};

export async function getGatewayStatus(deviceId: string): Promise<GatewayStatus> {
  return get<Gateway>(`gateway/${deviceId}/status`);
}

export async function searchGateways({ networkId, simCardIdentifier }: { networkId?: string, simCardIdentifier?: string }) {
  return get<Gateway[]>(`gateway`, { networkId, simCardIdentifier });
}

/**
 * Get a map of all the devices this gateway has seen in the last 24 hours
 */
export async function getGatewaySeenDevices24Hours(gatewayId: string): Promise<Record<string, string>> {
  return get<[string, string]>(`gateway/${gatewayId}/seendevices`);
}

export interface NewGateway extends Omit<Gateway, 'id' | 'deviceId'> {
  serialNumber: string;
  sku: string;
}

export function createGateway(newGateway: NewGateway): Promise<Gateway> {
  return post<Gateway>('/gateway', {
    ...newGateway,
  });
}

export type GatewayConnectionHistory = {
  connection_status: 1 | 0;
  timestamp: string;
};

/** Get the last 24 hours of gateway connections */
export async function getGatewayConnectionHistory(
  gatewayId: string,
  opts?: { startTimestamp?: string }
): Promise<GatewayConnectionHistory[]> {
  return get<GatewayConnectionHistory>(`gateway/${gatewayId}/connectionhistory`, opts);
}
