import { MenuItem, Select, SelectProps } from '@mui/material';
import { CurrencyCode, CurrencyDescription } from 'src/types/CurrencyCode';

interface CurrencySelectorProps extends Omit<SelectProps, 'onChange' | 'value'> {
  selectedCurrency: CurrencyCode;
  onChange: (currency: CurrencyCode) => void;
}

export default function CurrencySelector({ selectedCurrency, onChange, ...other }: CurrencySelectorProps) {
  return (
    <Select
      variant="filled"
      placeholder="Select Currency"
      onChange={(e) => onChange(e.target.value as CurrencyCode)}
      value={selectedCurrency}
      sx={{
        width: '100%',
        marginLeft: -2,
        background: 'none',
        border: '1px solid lightgray',
        borderRadius: 1,
        outline: 'none',
        ':before': {
          border: 'none !important',
        },
        ':after': {
          border: 'none !important',
        },
        '.MuiSelect-root': {
          padding: '10px 15px',
          borderRadius: '16px !important',
        },
      }}
      {...other}
    >
      {Object.keys(CurrencyCode).map((currency) => (
        <MenuItem value={currency}>{CurrencyDescription[currency]}</MenuItem>
      ))}
    </Select>
  );
}
