import * as Circuit from './routes/circuit';
import * as Customer from './routes/customer';
import * as CustomerDeviceAssignment from './routes/customer-device-assignment';
import * as CustomerInvoiceRoutes from './routes/customer-invoice';
import * as CustomerOrderRoutes from './routes/customer-order';
import * as CustomerOrderItemRoutes from './routes/customer-order-item';
import * as DeviceInventoryRoutes from './routes/device-inventory-log';
import * as PriceSetItemTypeRoutes from './routes/price-set-item-type';
import * as DeviceAssignmentRoutes from './routes/device-assignment';
import * as DeviceContractRoutes from './routes/device-contract';
import * as DeviceContractLedgerRoutes from './routes/device-contract-ledger';
import * as DeviceRoutes from './routes/device';
import * as GatewayRoutes from './routes/gateway';
import * as OrganisationRoutes from './routes/organisation';
import * as UserOrganisationRoutes from './routes/user-organisation';
import * as PriceSetRoutes from './routes/price-set';
import * as PriceSetItemRoutes from './routes/price-set-item';
import * as PropertyRoutes from './routes/property';
import * as LegacyRoutes from './routes/legacy-routes';
import * as StockaRoutes from './routes/stocka';
import * as XeroRoutes from './routes/xero';
import * as InsightHighlightVotesRoutes from './routes/insight-highlight-votes';

const TetherApi = {
  ...LegacyRoutes,
  ...Circuit,
  ...Customer,
  ...CustomerDeviceAssignment,
  ...CustomerInvoiceRoutes,
  ...CustomerOrderRoutes,
  ...CustomerOrderItemRoutes,
  ...DeviceInventoryRoutes,
  ...PriceSetItemTypeRoutes,
  ...DeviceAssignmentRoutes,
  ...DeviceContractRoutes,
  ...DeviceContractLedgerRoutes,
  ...DeviceRoutes,
  ...GatewayRoutes,
  ...OrganisationRoutes,
  ...UserOrganisationRoutes,
  ...PriceSetRoutes,
  ...PriceSetItemRoutes,
  ...PropertyRoutes,
  ...StockaRoutes,
  ...XeroRoutes,
  ...InsightHighlightVotesRoutes
};

export default TetherApi;
