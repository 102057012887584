import { Device } from 'src/types/Device';
import { New } from 'src/lib/dbHelper';
import { PriceSetItem } from 'src/types/PriceSetItem';
import { UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { get, post, put } from '../utils';

enum QueryKeys {
  'PriceSetItem' = 'PriceSetItem',
}

export async function getPriceSetItem(sku: string, priceSetId: string): Promise<Device> {
  return get<PriceSetItem>(`priceset/${priceSetId}/item/${sku}`);
}

export async function createPriceSetItem(priceSetItem: New<PriceSetItem>): Promise<PriceSetItem> {
  return post<PriceSetItem>('pricesetitem', priceSetItem);
}

export async function setPriceSetItem(priceSetItem: PriceSetItem): Promise<PriceSetItem> {
  return put<PriceSetItem>(`priceset/${priceSetItem.priceSetId}/item/${priceSetItem.sku}`, { ...priceSetItem });
}

export async function getPriceSetItems(priceSetId: string): Promise<PriceSetItem[]> {
  return get<PriceSetItem[]>(`priceset/${priceSetId}/item`);
}

export function useGetPriceSetItem(sku: string, priceSetId: string): UseQueryResult<PriceSetItem, Error> {
  return useQuery([QueryKeys.PriceSetItem, sku, priceSetId], () => getPriceSetItem(sku, priceSetId));
}

export function useGetPriceSetItems(
  priceSetId: string,
  options?: UseQueryOptions<PriceSetItem[], Error>
): UseQueryResult<PriceSetItem[], Error> {
  return useQuery([QueryKeys.PriceSetItem, priceSetId], () => getPriceSetItems(priceSetId), options);
}

export function useSetPriceSetItemMutation() {
  const queryClient = useQueryClient();

  return useMutation((priceSetItem: PriceSetItem) => setPriceSetItem(priceSetItem), {
    onSuccess: () => {
      queryClient.resetQueries(QueryKeys.PriceSetItem);
    },
  });
}
