import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import useLog from "src/hooks/useLog";
import { Circuit } from "src/types/Circuit";
import { get } from "../utils";

enum QueryKeys {
  Circuit = 'Circuit'
}

export async function getCircuitById(id: string): Promise<Circuit> {
  return get(`circuit/${id}`);
}

export function useGetCircuit(id: string, options?: UseQueryOptions<Circuit, AxiosError>) {
  const log = useLog();

  return useQuery<Circuit, AxiosError>([QueryKeys.Circuit, id], async () => getCircuitById(id),
    {
      onError: (err) => {
        if (err instanceof Error) {
          log.error(`There was a problem loading Circuit data: ${err.message}`, err);
        }
      },
      ...options
    });
};