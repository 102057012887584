import { Box, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { CurrencyDescription } from 'src/types/CurrencyCode';
import TetherApi from 'src/lib/tether-microservices/TetherApi';
import LoadingScreen from '../LoadingScreen';

interface PriceSetSelectorProps extends Omit<SelectProps, 'onChange' | 'value'> {
  selectedPriceSetId: string;
  onChange: (priceSetId: string) => void;
}

export default function PriceSetSelector({ selectedPriceSetId, onChange, ...other }: PriceSetSelectorProps) {
  const { data: priceSets = [], isLoading, error } = TetherApi.useSearchPriceSets();

  return (
    <Select
      variant="filled"
      placeholder="Select Currency"
      onChange={(e) => onChange(e.target.value as string)}
      value={selectedPriceSetId}
      sx={{
        width: '100%',
        marginLeft: -2,
        background: 'none',
        border: '1px solid lightgray',
        borderRadius: 1,
        outline: 'none',
        ':before': {
          border: 'none !important',
        },
        ':after': {
          border: 'none !important',
        },
        '.MuiSelect-root': {
          padding: '10px 15px',
          borderRadius: '16px !important',
        },
      }}
      {...other}
    >
      {isLoading && <LoadingScreen />}
      {!isLoading && error && <div>Error</div>}
      {!isLoading &&
        !error &&
        priceSets.map((priceSet) => (
          <MenuItem value={priceSet.id}>
            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary" variant="body2">
                {priceSet.name}
              </Typography>
              <Box mt={-0.5}>
                <Typography color="textSecondary" variant="caption">
                  {priceSet.description}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {' '}
                  - {CurrencyDescription[priceSet.currency]}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
    </Select>
  );
}
