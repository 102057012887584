import { useEffect, useState, type FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Box, Card, CardHeader, Divider, IconButton } from '@mui/material';
import ArrowRightIcon from '../../icons/ArrowRight';
import Scrollbar from '../Scrollbar';
import LoadingScreen from '../LoadingScreen';
import TetherTable from '../table/TetherTable';
import { DisplayType } from '../table/TetherTableColumn';
import { searchRoomLocations } from 'src/lib/tether-microservices/routes/property';
import useLog from 'src/hooks/useLog';
import { Room } from 'src/types/Room';

interface Props {
    room: Room;
}

const RoomRoomLocations: FC<Props> = ({ room }) => {
  const { organisationId } = useParams();

  const log = useLog();

  const [roomLocations, setRoomLocations] = useState(null);

  useEffect(() => {
    searchRoomLocations({ roomId: room.id })
        .then(setRoomLocations)
        .catch(err => log.error(err.message));
  }, [log, room]);

  // console.log({ roomsById });
  return (
    <Card>
      <CardHeader title="Room Locations" />
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          {roomLocations === null && (
            <Box sx={{ height: 300 }}>
              <LoadingScreen sx={{ width: '80px' }} />
            </Box>
          )}
          {(room && roomLocations) && (
            <TetherTable
              data={roomLocations}
              getRowNavigationRoute={(location) =>
                `/organisations/${organisationId}/installations/${room.propertyId}/roomlocations/${location.id}`
              }
              columns={[
                {
                  attribute: 'id',
                  label: 'ID',
                },
                {
                  attribute: 'name',
                  label: 'Zone Name',
                },
                {
                  attribute: 'createdAt',
                  displayType: DisplayType.DATETIME,
                },
              ]}
              rowActions={(location) => (
                <IconButton
                  component={RouterLink}
                  to={`/organisations/${organisationId}/installations/${room.propertyId}/installationlocations/${location.id}`}
                  size="large"
                >
                  <ArrowRightIcon fontSize="small" />
                </IconButton>
              )}
              rowRenderKeyAttribute="id"
            />
          )}
        </Box>
      </Scrollbar>
    </Card>
  );
};

export default RoomRoomLocations;
