import { FC, useState } from 'react';
import { Box, ButtonProps, Button } from '@mui/material';
import LoadingScreen from './LoadingScreen';

interface TetherButtonProps extends ButtonProps {
  loading?: boolean;
}

const TetherButton: FC<TetherButtonProps> = (props) => {
  const { loading, children, onClick, disabled, sx, ...other } = props;
  const [processing, setProcessing] = useState(false);

  return (
    <Button
      {...other}
      onClick={async (event) => {
        setProcessing(true);
        await Promise.resolve(onClick(event));
        setProcessing(false);
      }}
      disabled={disabled || processing}
      sx={{
        ...sx,
      }}
    >
      {children}
      <Box sx={{ display: 'flex', maxHeight: '15px', maxWidth: '15px', marginLeft: '5px' }}>
        {(loading || processing) && <LoadingScreen sx={{ width: '15px', display: 'flex' }} />}
      </Box>
    </Button>
  );
};

export default TetherButton;
