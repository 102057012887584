import Box from '@mui/material/Box';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { DialogProvider } from './contexts/DialogContext';
import { LogProvider } from './contexts/LogContext';
import useAuth from './hooks/useAuth';
import useSettings from './hooks/useSettings';
import { EntityPreviewProvider } from './contexts/EntityPreviewContext';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import Log from './Log';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from './theme';
import Router from './routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const { settings } = useSettings();
  const auth = useAuth();

  const theme = createTheme({
    theme: settings.theme,
    roundedCorners: true,
  });
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <DialogProvider>
          <SnackbarProvider dense maxSnack={3}>
            <LogProvider>
              <EntityPreviewProvider>
                <SettingsDrawer />
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      width: settings.showLog ? `${100 - settings.logWidth}vw` : '100vw',
                      minHeight: '100vh',
                    }}
                  >
                    {auth.isInitialized ? <Router /> : <SplashScreen />}
                  </Box>
                  {settings.showLog && <Log showDebug={settings.showDebug} />}
                </Box>
              </EntityPreviewProvider>
            </LogProvider>
          </SnackbarProvider>
        </DialogProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
